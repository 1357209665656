.solution {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;

    &__item {
        color: @dark3;
    }

    &__img {
        width: 187px;
        height: 187px;
        background-color: #fff;
        border-radius: 50%;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__name {
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 8px;
        transition: color 0.3s ease;
    }

    &__text {
        font-size: 15px;
        line-height: 160%;
        color: @dark4;
    }

    &__item {
        width: calc(~"20% - 24px");
        margin: 0 12px;
    }
}


@media(min-width: 960px) {
    .solution {
        &__item {
            &:hover {
                .solution {
                    &__name {
                        color: @green;
                    }
                }
            }
        }
    }
}

@media(max-width: 1140px) { 
    .solution {
        &__img {
            width: 148px;
            height: 148px;
            margin-bottom: 20px;

            img {
                max-width: 60%;
                max-height: 60%;
            }
        }

        &__text {
            font-size: 12px;
            line-height: 165%;  
        }

        &__name {
            font-size: 15px;
            line-height: 132%;
        }
    }
}

@media(max-width: 960px) { 
    .solution {
        margin-bottom: -40px;

        &__item {
            width: calc(~"33.3% - 24px");
            margin-bottom: 40px;
        }
    }
}

@media(max-width: 640px) {
    .solution {
        &__item {
            width: calc(~"50% - 24px");
        }
    }
}

@media(max-width: 480px) {
    .solution {
        margin-bottom: -32px;
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(~"100% - 24px");
            margin-bottom: 32px;
        }

        &__text {
            width: 240px;
            text-align: center;
        }

        &__img {
            width: 160px;
            height: 160px;
            margin-bottom: 16px;
        }
    }
}