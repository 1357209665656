.notfound {
    background: linear-gradient(180deg, #E0E0E0 0%, #FFFFFF 100%);
    padding-bottom: 144px;

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        
    }

    &__body {
        max-width: 720px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        margin-top: -303px;
    }

    &__text {
        font-size: 20px;
        line-height: 156%;
        text-align: center;
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 22px;

        .btn {
            margin: 0 6px;
        }
    }
}