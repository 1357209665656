.wrapper {
    overflow-x: hidden;
    padding-bottom: 612px;
    padding-top: 144px;
    
    &--grey {
        background-color: @lightgrey;
    }

    &--index {
        .wrapper {
            &__container {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    &--pb0 {
        .wrapper {
            &__container {

                padding-bottom: 0;
            }
        }  
    }
    
    &__container {
        position: relative;
        z-index: 3;
        padding-top: 20px;
        padding-bottom: 145px;
        background: #F4F3F4;

        &--pt0 {
            padding-top: 0 !important;
        }
    }
}


@media (max-width: 1440px) and (min-width: 1141px) {
    .wrapper {
        padding-bottom: 42vw;
        padding-top: 9vw;

        &__container {
            padding-top: 1.1vw;
            padding-bottom: 8vw;
        }
    }
}

@media(max-width: 1140px) { 
    .wrapper {
        padding-bottom:  490px;
        padding-top: 71px;
        
        &__container {
            padding-top: 17px;
            padding-bottom: 50px;
        }
    }

    
}

@media(max-width: 960px) {
    .wrapper {
        padding-top: 64px;
        padding-bottom:  492px;
    }
}

@media(max-width: 640px) {
    .wrapper {
        padding-top: 56px;
    }
}