.container {
    width: 1340px;
    padding: 0 15px;

    &::before {
        display: none;
    }

    &::after {
        display: none;
    }
}


@media (max-width: 1440px) and (min-width: 1141px) {
    .container {
        width: 100%;
        padding: 0 4.5vw; 
    }
}

@media(max-width: 1140px) {
    .container {
        width: 100%;
        padding: 0 40px;
    }
}

@media(max-width: 960px) {
    .container {
        padding: 0 38px;
    }
}

@media(max-width: 640px) {
    .container {
        padding: 0 32px;
    }
}

@media(max-width: 480px) {
    .container {
        padding: 0 24px;
    }
}