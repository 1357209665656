.tab {
    &__control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    &__block {
        display: none;

        &.is-active {
            display: block;
        }
    }

    &__link {
        display: flex;
        align-items: center;

        div {
            cursor: pointer;
            display: inline-flex;
            margin-right: 23px;
            font-weight: bold;
            font-size: 20px;
            line-height: 132%; 
            padding-bottom: 5px;
            border-bottom: 4px solid fade(#333234 , 10);
            transition: border-color 0.3s ease;

            &.is-active {
                cursor: initial;
                border-color: @green;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@media(min-width: 960px) { 
    .tab {
        &__link {
            div {
                &:hover {
                    border-bottom-color: fade(#333234 , 30);
                }
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .tab {
        &__control {
            margin-bottom: 2.7vw;
        }

        &__link {
            div {
                font-size: 1.4vw;
                margin-right: 1.6vw;
                padding-bottom: 0.4vw;
                border-bottom-width: 0.3vw;
            }
        }
    }
}

@media(max-width: 960px) { 
    .tab {
        &__control {
            margin-bottom: 23px;
        }
        
        &__link {
            div {
                margin-right: 23px;
                font-size: 15px;
                line-height: 132%;
            }
        }
    }
}