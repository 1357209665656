.video-block {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 656px;
    height: 438px;
    border-radius: 20px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    
    &::before {
        content:" ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }

    &__wrap {
        max-width: 396px;
    }

    &__title {
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 48px;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        width: 104px;
        height: 74px;
        background-color: @red;
        border-radius: 15px;
        transition: background-color 0.3s ease;

        .svg-icon {
            width: 27px;
            height: auto;

            use {
                fill: #fff;
            }
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &--big {
        margin-top: 47px;
    }

    &--dark {
        .video-block {

            &__icon {
                width: 68px;
                height: 48px;
                background-color: rgba(0, 0, 0, 0.6);

                .svg-icon {
                    width: 18px;
                    height: auto;
                }
            }

            &::before {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
            }
        }
    }

    &__name {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 156%;
        color: #fff;
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 22px;
    
        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
            background: linear-gradient(95.59deg, #44B86A 0%, #64A27A 100%);
            border-radius: 50%;
            margin-right: 12px;
            width: 48px;
            height: 48px;
        }
    }

    &__slider {
        &.slider-block {
            .slick-list {
                overflow: visible;
                margin: 0 -24px;
            }
        }

        .slick-slide {
            padding: 0 24px;

            &.slick-active {
                .video-block {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }

        .video-block {
            width: 100%;
            height: 288px;
            margin-top: 0;
            opacity: 0.2;
            pointer-events: none;
            transition: opacity 0.3s ease;
        }
    }

    &__plit {

        .btn {
            width: 100%;
        }
      

        &-wrap {
            display: flex;
            flex-wrap: wrap;
            margin-left: -20px;
            margin-right: -20px;

            &>div {
                width: calc(~"50% - 40px");
                margin: 0 20px 40px;
            }
        }

        .video-block {
            width: 100%;
            margin: 0;
            height: 306px;
        }
    }

    &--aside {
        width: 100%;
        height: 192px;
        margin-bottom: 32px;

        .video-block {
            &__name {
                padding: 16px;
                font-size: 15px;
                line-height: 120%;

                &-icon {
                    width: 32px;
                    height: 32px;

                    svg {
                        width: 15px;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .video-block {
        &--big {
            &:hover {
                color: @green;
            }
        }

        &--dark {
            &:hover {
                .video-block {
                    &__icon {
                        width: 68px;
                        height: 48px;
                        background-color: @lg3;
                    }
                }
            }
        }
       
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .video-block {
        &__plit {

            &-wrap {
                margin-left: -1.4vw;
                margin-right: -1.4vw;

                &>div {
                    width: calc(~"50% - 2.8vw");
                    margin-bottom: 2.8vw;
                    margin-left: 1.4vw;
                    margin-right: 1.4vw;
                }
            }

            .video-block {
                border-radius: 16px;

                height: 21.2vw;
            }
        }

        &__icon {
            width: 5vw;
            height: 4vw;
            border-radius: 10px;
        }

        &__name {
            font-size: 1.3vw;

            &-icon {
                width: 3.6vw;
                height: 3.6vw;

                svg {
                    width: 1.6vw;
                    height: auto;
                }
            }
        }

        &--aside {
            height: 12vw;
            margin-bottom: 2vw;
            border-radius: 10px;

            .video-block {
                &__name {
                    padding: 10px;
                    font-size: 1vw;

                    &-icon {
                        width: 2vw;
                        height: 2vw;
                    }
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .video-block {
        width: 500px;
        height: 334px;
        flex: none;

        &--aside {
            width: 100%;
            height: 162px;
        }

        &__wrap {
            padding-right: 15px;
        }

        &__title {
            font-size: 20px;
            line-height: 23px;
        }

        &__slider {
            .slick-slide {
                padding: 0 16px;
            }

            .video-block {
                width: 320px;
                height: 220px;
            }
        }

        &__plit {

            &__wrap {
                margin-left: -11px;
                margin-right: -11px;

                &>div {
                    width: calc(~"50% - 22px");
                    margin-left: 11px;
                    margin-right: 11px;
                    margin-bottom: 32px;
                }
            }


            .video-block {
                height: 30vw;
                
            }
        }
    }
}

@media(max-width: 960px) { 
    .video-block {
        &__container {
            display: block;

            .video-block {
                width: 100%;
                height: 59vw;
            }
        }

        

        &__body {
            margin-bottom: 45px;
        }

        &__wrap {
            max-width: initial;
        }

        &__title {
            margin-bottom: 32px;
        }

        &__name {
            font-size: 14.25px;
            line-height: 165%;    
            
            &-icon {
                width: 32px;
                height: 32px;

                svg {
                    width: 15px;
                    height: auto;
                }
            }
        }

        &__plit {
            &-wrap {
                display: block;
                margin: 0 0 24px;
                margin-right: -21px;

                &>div {
                    width: auto;
                    margin: 0 -16px;
                }
            }

            .video-block {
                width: 320px;
                height: 220px;
            }

            .slick-slide {
                padding: 0 16px;
            }
        }
    }
}

@media(max-width: 640px) {
    .video-block {
        &__icon {
            width: 72px;
            height: 52px;

            .svg-icon {
                width: 19px;
            }
        }

        &__container {
            .video-block {
                height: 62vw;
                margin-left: -32px;
                margin-right: -32px;
                width: calc(~"100% + 64px");
                border-radius: 0;
            }
        }
    }
}

@media(max-width: 480px) {
    .video-block {
        &__title {
            font-size: 24px;
            line-height: 28px;
            text-align: center;   
        }

        &__container {
            .video-block {
                height: 83vw;
                margin-left: -24px;
                margin-right: -24px;
            }
        }

        &__wrap {
            padding-right: 0;
        }
    }
}