
h1 {
    font-weight: bold;
    font-size: 44px;
    line-height: 52px;
    font-family: 'Roboto', sans-serif;
}

h2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    font-family: 'Roboto', sans-serif;
}

h3 {
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

h4 {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 132%;
    margin: 0;
}

h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;
    font-family: 'Roboto', sans-serif;
}


@media (max-width: 1440px) and (min-width: 1141px) {
    h1 {
        font-size: 3vw;
        line-height: 1.2;
    }

    h3 {
        font-size: 1.95vw; 
    }
}

@media(max-width: 1140px) { 
    h1 {
        font-size: 36px;
        line-height: 42px;
    }

    h2 {
        font-size: 24px;
        line-height: 28px;
    }

    h3 {
        font-size: 20px;
        line-height: 23px;
    }

    h5 {
        font-size: 14.25px;
        line-height: 165%;
    }
}

@media(max-width: 960px) { 
    h1 {
        font-size: 30px;
        line-height: 35px;
    }
}

@media(max-width: 480px) { 
    h1 {
        font-size: 24px;
        line-height: 28px;
    }

    h3 {
        font-size: 20px;
        line-height: 23px;
    }
}

