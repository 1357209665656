.footer {
    position: relative;
    z-index: 2;
    height: 612px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    &__top {
        padding-top: 89px;
        padding-bottom: 82px;
        background-color: @dark3;

        &-menu {
            width: 66.7%;
            margin-top: 16px;
        }

        .container {
            display: flex;
            justify-content: space-between;
        }
    }

    .logo {
        margin-top: 0;
        margin-left: -6px;
    }

    &__info {
        max-width: 313px;

        div {
            font-size: 15px;
            line-height: 150%;
            color: @dark4;
            margin-bottom: 14px;
            
            &:last-child {
                margin-bottom: 0;
            }

            a {
                color: @dark4;
            }
        }
    }

    &__bottom {
        padding: 27px 0;
        background-color: #262526;

        &-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 66.7%;
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
        }
    }

    .phone {
        font-size: 36px;
        line-height: 42px;
        color: #fff;
    }

    .menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 36px 0 0;

        a {
            display: inline-flex;
            margin: 0 0 18px;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            color: #fff;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .footer {
        height: 42vw;
        .logo {
            width: 13vw;
        }

        .phone {
            font-size: 2.6vw;
        }

        .menu {
            margin-top: 2vw;

            a {
                font-size: 1.2vw; 
                margin-bottom: 1.3vw;
            }
        }

        &__info {
            width: 24%;
            max-width: initial;

            div {
                font-size: 1vw;
                margin-bottom: 0.7vw;
            }
        }

        &__bottom {
            &-wrap {
                width: 63.2%;
            }
        }

        &__top {
            padding-top: 6vw;
            padding-bottom: 6vw;

            &-menu {
                margin-top: 3px;
                width: 63.2%;
            }
        }
    }
}

@media(max-width: 1140px) {
    .footer {
        height: 490px;

        .phone {
            font-size: 26px;
            line-height: 30px;
        }

        .logo {
            height: 44px;

            img {
                max-height: 100%;
            }
        }

        &__info {
            width: 33.3%;
            flex: none;
            padding-right: 22px;

            div {
                font-size: 13px;
                line-height: 150%;
            }
        }

        &__top {
            padding-top: 37px;
            padding-bottom: 61px;

            &-wrap {
                width: 33.3%;
                padding-top: 15px;
            }

            &-menu {
                width: 66.7%;
                padding-left: 10px;
            }
        }

        .menu {
            margin-top: 21px;

            a {
                font-size: 14px;
                line-height: 100%;
            }
        }
    }
}

@media(max-width: 960px) {
    .footer {
        height: 492px;
        
        .logo {
            height: 48px;
            margin-left: 0;
        }

        .menu {
            display: none;
        }

        &__info {
            padding: 0;
            width: 100%;
            order: 2;
            text-align: center;
            max-width: initial;

            div {
                margin-bottom: 9px;
            }
        }

        &__bottom {
            padding-top: 33px;
            padding-bottom: 40px;

            .container {
                flex-direction: column;
            }

            &-wrap {
                
                flex-direction: column;
                order: 1;

                .phone__block {
                    order: 2;
                    margin-bottom: 29px;

                    .phone {
                        &__title {
                            margin-bottom: 5px;
                            text-align: center;
                        }
                    }
                }

                .social {
                    order: 1;
                    margin-bottom: 32px;
                }
            }
            
        }

        &__top {
            padding-top: 32px;
            padding-bottom: 0;
            background: #262526;

            &-menu {
                display: none;
            }

            &-wrap {
                display: flex;
                width: 100%;
                justify-content: center;
            }
        }
    }
}

@media(max-width: 640px) {
    .footer {
        &__bottom {
            &-wrap {
                width: 100%;
            }
        }
    }
}