.text-block {
    padding-left: 75px;
    padding-top: 64px;

    &__top {
        .select {
            display: none;
        }
    }

    &__text {
        font-size: 18px;
        line-height: 156%;
        margin-top: 32px;
    }
}


@media (max-width: 1440px) and (min-width: 1141px) {
    .text-block {
        &__text {
            font-size: 1.2vw;
            line-height: 1.6;
            margin-top: 2vw;
        }
    }
}

@media(max-width: 1140px) {
    .text-block {
        padding-left: 4px;
        padding-top: 62px;
        padding-right: 42px;

        &__text {
            font-size: 15px;
            line-height: 165%;
            margin-top: 27px;
        }

        h1 {
            font-size: 30px;
            line-height: 35px;
            margin-top: 0;
        }
    }
}

@media(max-width: 960px) { 
    .text-block {
        padding-top: 0;
        padding-bottom: 48px;
        padding-right: 0;

        &__text {
            margin-top: 16px;
        }

        &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
                margin-bottom: 0;
            }

            .select {
                width: 257px;
                display: flex;
            }
        }
    }
}

@media(max-width: 640px) { 
    .text-block {
        padding-bottom: 32px;

        &__text {
            margin-top: 24px;
        }

        &__top {
            display: block;

            .select {
                margin-top: 24px;
                width: 100%;
            }
        }
    }
}