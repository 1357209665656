.catalog-item {
    display: block;  
    
    color: inherit;
    position: relative;
    

    &__wrap {
        padding: 40px 32px 32px;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        background-color: #fff;
        box-shadow: 0px 13px 32px rgba(0, 0, 0, 0.03);
        transition: box-shadow 0.3s ease;
    }

    &__slider {
        .catalog-item {
            width: 100%;
            opacity: 0.2;
            pointer-events: none;
            transition: opacity 0.3s ease;
        }

        .slick-slide {
            padding: 30px 16px;

            &.slick-active {
                .catalog-item {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }

    &__status {
        display: inline-flex; 
        padding: 4px 14px;
        letter-spacing: 0.02em;
        color: #fff;
        font-weight: bold;
        font-size: 15px;
        line-height: 160%;
        border-radius: 0px 16px 16px 0px;

        &-wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 0px 16px 16px 0px;
        }

        &--stock {
            background-color: #CA3D67;
        }

        &--new {
            background-color: #4DD678;
        }

        &--spec {
            background-color: #E3517D;
        }

        &--prof {
            background-color: #FEE703;
            color: #333234;
        }
    }

    &__img {
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__name {
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        transition: color 0.3s ease;
    }

    &__type {
        font-size: 15px;
        line-height: 160%;
        color: fade(@lightbrown , 50);
    }

    &__text {
        font-size: 15px;
        line-height: 160%; 
        margin-top: 8px;
    }

    &--card {
        margin-bottom: 39px;

        .catalog-item {
            &__wrap {
                padding: 20px 15px;
            }

            &__img {
                margin-bottom: 0;
            }
        }
    }


    &__list {
        display: flex;
        flex-wrap: wrap;

        &--programm {
            margin-left: -16px;
            margin-right: -16px;
            margin-bottom: -16px;
            

            .catalog-item {
     
                text-align: center;
                width: calc(~"25% - 32px");
                margin: 0 16px 16px;

                &__type {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 156%;
                }

                &__wrap {
                    padding: 25px;
                }

                &__img {
                    height: 152px;
                }


                &__name {
                    font-size: 20px;
                    line-height: 132%;
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .catalog-item {
        &:hover {
            .catalog-item {
                &__name {
                    color: @lg3;
                }

                &__wrap {
                    box-shadow: 0px 13px 32px rgba(0, 0, 0, 0.1), 0px 13px 32px rgba(0, 0, 0, 0.03);
                }
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .catalog-item {
        &__list {
            &--programm {
                margin-left: -1vw;
                margin-right: -1vw;

                .catalog-item {
                    width: calc(~"25% - 2vw");
                    margin-left: 1vw;
                    margin-right: 1vw;

                    &__img {
                        height: 11vw;
                        margin-bottom: 0.9vw;
                    }

                    &__name {
                        font-size: 1.4vw;
                        margin-bottom: 0.3vw;
                    }

                    &__type {
                        font-size: 1.1vw;
                    }

                    &__wrap {
                        padding: 16px;
                        border-radius: 15px;
                    }
                }
            }
        }
    }
}

@media(max-width: 1140px) { 
    .catalog-item {
        &__name {
            font-size: 17px;
            line-height: 132%;
        }

        &__type {
            font-size: 12px;
            line-height: 165%;
        }

        &__status {
            padding-top: 6px;
            padding-bottom: 6px;
            font-size: 13px;
            line-height: 160%;
        }

        &__text {
            font-size: 12px;
            line-height: 165%;
        }

        &__img {
            height: 190px;
        }

        &__wrap {
            padding: 32px 24px 24px;
        }

        &__slider {
            .catalog-item {
                width: 240px; 
            }
        }

        &--card {
            margin-bottom: 35px;
            
            .catalog-item {
                &__wrap {
                    padding: 5px;
                    height: 198px;
                }

                &__status {
                    padding-top: 4px;
                    padding-bottom: 4px;
                    font-size: 15px;
                    line-height: 160%;
                }
            }
        }

        &__list {
            &--programm {
                margin-left: -8px;
                margin-right: -8px;
                margin-bottom: 0;

                .catalog-item {
                    
                    width: calc(~"25% - 16px");
                    margin: 0 8px;

                    &__wrap {
                        padding-top: 16px;
                        padding-bottom: 16px;
                    }

                    &__type {
                        font-size: 14.25px;
                        line-height: 165%;
                    }

                    &__name {
                        font-size: 17px;
                        line-height: 132%;
                        margin-bottom: 7px;
                    }

                    &__img {
                        height: 100px;
                        margin-bottom: 8px;
                    }
                }
                
            }
        }
    }
}

@media(max-width: 960px) {
    .catalog-item {
        
        &__list {
            &--programm {
                margin-left: -11px;
                margin-right: -11px;
                margin-bottom: -21px;

                .catalog-item {
                 
                    width: calc(~"50% - 22px");
                    margin: 0 11px 24px;

                    &__body {
                        text-align: left;
                    }

                    &__img {
                        width: 60px;
                        height: 60px;
                        margin-right: 6px;
                        border-radius: 16px;
                        background-color: #fff;
                        overflow: hidden;
                    }

                    &__name {
                        margin-bottom: 0;
                    }

                    &__type {
                        font-size: 18px;
                        line-height: 156%;
                    }

                    &__wrap {
                        padding: 0;
                        border-radius: 0;
                        box-shadow: none;
                        display: flex;
                        align-items: center;
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

@media(max-width: 480px) {
    .catalog-item {
        &__slider {
            .slick-slide {
                padding-left: 12px;
                padding-right: 12px;
            }

            &.slick-list {
                margin-left: -12px;
                margin-right: -12px;
            }
        }

        &__list {
            &--programm {
                margin-bottom: 0;
                
                .catalog-item {
                    width: calc(~"100% - 22px");
                    margin: 0 11px 14px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
              
            }
        }
    }
}