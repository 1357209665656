.video-link {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    line-height: 21px;
    color: @dark4;
    transition: color 0.3s ease;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        width: 49px;
        height: 34px;
        background-color: @green;
        border-radius: 10px;
        margin-right: 10px;

        .svg-icon {
            width: 13px;
            height: auto;

            use {
                fill: #fff;
            }
        }
    }
}

@media(min-width: 960px) {
    .video-link {
        &:hover {
            color: @green;
        }
    }
}

@media(max-width: 1140px) {
    .video-link {
        span {
            font-size: 14.25px;
            line-height: 165%;
        }

        &__icon {
            width: 32px;
            height: 22.2px; 
            border-radius: 7px;

            .svg-icon {
                width: 9px;
                height: auto;
            }
        }
    }
}