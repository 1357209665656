.logo {
    display: inline-flex;
    align-items: center;
    margin-top: 5px;
  

    img {
        display: block;
        transition: width 0.3s, height 0.3s ease;
    }
}