.aside-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    box-shadow: 0px 13px 32px rgba(0, 0, 0, 0.03);
    border-radius: 16px;
    padding: 28px;

    .svg-icon {
        display: block;
        flex: none;
        margin-right: 24px;

        use {
            fill: @lg2;
        }
    }

    a {
        display: inline-flex;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        padding: 13px 0;
        color: @green;

        &.is-active {
            .svg-icon {
                use {
                    fill: @green;
                }
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .aside-menu {
        padding: 2.4vw 2vw;

        .svg-icon {
            width: 0.6vw;
            height: auto;
            margin-right: 1.5vw;
        }

        a {
            padding: 0;
            font-size: 1.3vw;
            margin-bottom: 1.4vw;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}