.popup {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 50;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &.is-open {
        opacity: 1;
        pointer-events: auto;
    }
    

    .agree {
        &__text {
            margin-top: 8px;
            font-size: 13px;
            line-height: 160%;  
            color: #6B6B6B;
        }
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(51, 50, 52, 0.25);
        filter: blur(4px);
    }

    &__btn {
        margin-top: 32px;
    }

    &__close {
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 0;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        .svg-icon {
            width: 14px;
            height: auto;

            use {
                stroke: #333234;
            }
        }
    }

    &__text {
        font-size: 16px;
        line-height: 156%;
        margin-bottom: 32px;
    }

    &__top {
        margin-bottom: 16px;
    }

    &__name {
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
    }

    &__wrap {
        width: 582px;
        background: #f4f3f4;
        border-radius: 12px;
        position: relative;
        z-index: 2;
        padding: 66px 64px 64px;
        max-height: 100%;
        overflow-y: auto;
    }

    &--line {
        .popup {
            &__wrap {
                width: 742px;
            }

            &__close {
                width: 44px;
height: 44px;
                top: 32px;
                right: 42px;
            }

            &__top {
                margin-bottom: 36px;
            }
        }
    }

    &--city {
        .popup {
            &__wrap {
                width: 641px;
                padding: 52px 64px;
                background-color: #fff;
            }

            &__top {
                margin-bottom: 25px;
            }

            &__close {
                width: 44px;
                height: 44px;
                top: 48px;
                right: 48px;

                .svg-icon {
                    width: auto;
                    height: auto;

                    use {
                        fill: #333234;
                    }
                }
            }
        }
    }
}