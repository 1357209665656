.share {
    &__title {
        font-size: 15px;
        line-height: 160%;
        margin-bottom: 4px;
        color: #333234;
    }

    &__body {
        display: flex;

        &-wrap {
            display: flex;
            align-items: center;

            .ya-share2__item_service_vkontakte .ya-share2__icon {
                background-image: url(../images/social/vk.png);
            }

            .ya-share2__item_more .ya-share2__icon_more {
                background-image: url(../images/social/more.png);
            }

            .ya-share2__item_service_facebook .ya-share2__icon {
                background-image: url(../images/social/facebook.png);
            }

            .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item {
                margin-top: 0;
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .ya-share2__badge {
                border-radius: 8px;
                background-color: @green !important;
                transition: background-color 0.3s ease;
            }

            .ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
                width: 40px;
                height: 40px; 
                background-size: auto;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
    
    .ya-share2__container_mobile .ya-share2__badge {
        border-radius: 8px;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 8px;
        width: 40px;
        border-radius: 8px;
        height: 40px; 
        background-color: @green;
        transition: background-color 0.3s ease;

        .svg-icon {
            use {
                stroke: #fff;
                fill: transparent;
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .share {
        &__title {
            font-size: 1vw;
        }

        .share__body-wrap .ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
            width: 2.5vw;
            flex: none;
            height: 2.5vw;
        }

        .share__body-wrap .ya-share2__list.ya-share2__list_direction_horizontal>.ya-share2__item {
            margin-right: 6px;
        }

        &__link {
            margin-right: 6px;
            width: 2.5vw;
            flex: none;
            height: 2.5vw;

            .svg-icon {
                width: 70%;
                height: auto;
            }
        }
    }
}

@media(max-width: 1140px) {
    .share {
        &__link {
            margin-right: 4px;
            width: 30px;
            height: 30px;
        }

        .share__body-wrap .ya-share2__list.ya-share2__list_direction_horizontal>.ya-share2__item {
            margin-right: 4px;
        }

        .share__body-wrap .ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
            width: 30px;
            height: 30px;
        }
    }
}

@media(min-width: 960px) {
    .share {
        &__link {
            &:hover {
                background-color: @lg3;
            }
        }

        &__body {
            .ya-share2__badge {
                &:hover {
                    opacity: 1;
                    background-color: @lg3 !important;
                }
            }
        }
    }
}