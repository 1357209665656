.schema {
    &__top {
        display: flex;
        position: relative;
    }

    &__back {
        position: absolute;
        left: 204px;
    }

    &__icon {
        display: none;
    }

    &__row {
        display: flex;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        &-body {
            position: relative;
        }
    }

    &__info {
        padding-top: 23px;
    }

    &__level {
        position: absolute;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 13px;
        line-height: 160%;
        height: 48px;
        width: 80px;
        top: 0;
        left: 0;

        span {
            position: relative;
        }

        &-wrap {
            opacity: 0.5;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            border-radius: 24px;
            right: 0;
        }
    }

    &__line {
        display: flex;
        position: absolute;
        right: 15px;
        bottom: 0;
        top: 40px;
        pointer-events: none;

        &-item {
            width: 80px;
            border-left: 1px dashed fade(#000000, 40);
        }
    }

    &__body {
        position: relative;
      

        &::before {
            content:" ";
            position: absolute;
            background-color: #fff;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100vw;
        }
    }

    &__main {
        flex-grow: 1;
    }

    &__wrap {
        position: relative;
        padding-top: 19px;
        padding-bottom: 80px;
    }

    &__title {
        flex-grow: 1;
    }

    &__img {
        display: flex;
        align-items: flex-end;
        position: relative;

        img {
            width: auto;
            height: auto;
            max-width: initial;
        }

        &-item {
            position: relative;
            display: flex;
            width: 80px;
            justify-content: center;
        }
    }

    &__name {
        width: 80px;
        flex: none;
        font-size: 13px;
        line-height: 120%;
        color: @dark4;
        text-align: center;

        span {
            background-color: #fff;
            padding-bottom: 10px;
        }
    }
}

@media(max-width: 1440px) {
    .schema {
        overflow-x: auto;

        &__back {
            left: 259px;
        }

        &__img {
            width: 1440px; 
        }

        &__wrap {
            &.container {
                width: 1340px;
                padding: 0 15px;
                padding-top: 19px;
                padding-bottom: 80px;
            }
        }

       /* &__level {
            width: 63px;
        }

        &__img {
            &-item {
                width: 63px;
            }
        }

        &__line {
            &-item {
                width: 63px;
            }
        }

        &__name {
            font-size: 10px;
            line-height: 120%;
            width: 63px;
        }*/

        &__body {
            width: 1440px; 

           &::before {
            width: 1440px; 
           }
        }

       /* &__icon {
            display: block;
            position: absolute;
        }*/
    }
}