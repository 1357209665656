.menu-top {
    height: 100%;
    margin-right: 27px;

    &__drop {
        position: absolute;
        left: 0;
        top: 100%;
        background: #F4F3F4;
        width: 156px;
        filter: drop-shadow(0px 13px 32px rgba(0, 0, 0, 0.03));
        opacity: 0;
        pointer-events: none;

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                margin-bottom: 1px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    line-height: 156%;
                    padding: 12px 16px;
                    color: inherit;
                    background-color: #fff;
                }
            }
        }
    }

    &>ul {
        height: 100%;
        display: flex;
        padding: 0;
        margin: 0;

        &>li {
            display: flex;
            align-items: center;
            height: 100%;
            position: relative;
            list-style: none;
            margin-right: 27px;

            &::before {
                content:" ";
                position: absolute;
                width: 0;
                bottom: 0;
                left: 0;
                height: 4px;
                background-color: @lg3;
                transition: width 0.3s ease;
            }

            &:last-child {
                margin-right: 0;
            }

            &>a {
                font-size: 16px;
                line-height: 156%;
                color: @lg2; 
            }
        }
    }  
}

@media(min-width: 960px) {
    .menu-top {
        height: 100%;
        margin-right: 27px;
    
        &>ul {
            &>li {
                &:hover {
                    &>a {
                        color: #fff;
                    }
                    .menu-top {
                        &__drop {
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }

                    &::before {
                        width: 100%;
                    }
                }
            }
        }

        &__drop {
            ul {
                li {
                    a {
                        &:hover {
                            color: @lg3;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .menu-top {
        &>ul {

            &>li {
                margin-right: 2vw;

                &>a {
                    font-size: 1.1vw;
                }
            }
        }
    }
}