.site {
    position: absolute;
    right: 15px;
    top: -134px;
    transition: opacity;

    svg {
        path {
            fill: #6B6B6B;
            transition: fill 0.3s ease;
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .site {
        top: -9.5vw;
        right: 4.5vw;
    }
}

@media(max-width: 1140px) { 
    .site {
        right: 40px;
        top: -110px;
    }
}

@media(max-width: 960px) { 
    .site {
        right: initial;
        top: initial;
        position: relative;
        order: 3;
        margin-top: 32px;
    }
}

@media(min-width: 960px) {
    .site {
        &:hover {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
}