.tag {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    &--inner {
        margin-bottom: 72px;
    }

    &__item {
        display: inline-flex;
        align-items: center;
        margin-right: 12px;
        margin-bottom: 16px;
        padding-right: 19px;
        font-size: 18px;
        line-height: 156%;
        background-color: #fff;
        color: inherit;
        border-radius: 24px;
        transition: background-color 0.3s, color 0.3s ease;

        &--all {
            padding: 6px 18px;
        }

        &.is-active {
            background-color: @green;
            color: #fff;
        }
    }

    &__img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 12px;
        flex: none;
        background-position: center;
        background-size: cover;
    }
}

@media(max-width: 1140px) {
    .tag {
        &__item {
            font-size: 14.25px;
            line-height: 165%;
            margin-right: 8px;
            margin-bottom: 16px;
        }

        &__img {
            width: 32px;
            height: 32px;
        }
    }
}