.map-info {
    width: 440px;
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 48px;
    position: relative;
    z-index: 2;
    margin-left: 81px;

    h1 {
        margin-top: 0;
        margin-bottom: 16px;
    }

    &__title {
        font-size: 15px;
        line-height: 160%; 
        color: @dark2;
        margin-bottom: 6px;
    }

    &__value {
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;

        a {
            color: #333234;

            &.is-green-text {
                color: @green;
            }
        }
    }

    &__item {
       margin-bottom: 24px;
    }

    &__btn {
        margin-top: 36px;
    }
}

.map {
    position: relative;
    height: 700px;
    margin-bottom: 92px;
    margin-top: -102px;
    z-index: 1;
    padding-top: 86px;

    &__block {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            pointer-events: none;
            background: rgba(51, 50, 52, 0.05);
        }
    }
}

@media(max-width: 1140px) {
    .map {
        height: 555px;
        margin-bottom: 50px;
        margin-top: -66px;
        padding-top: 70px;
    }

    .map-info {
        width: 345px;
        padding: 36px;
        border-radius: 12.6711px;
        margin-left: 0;

        &__item {
            margin-bottom: 17px;
        }

        &__btn {
            margin-top: 28px;
        }

        &__title {
            font-size: 12px;
            line-height: 165%;
        }

        &__value {
            font-size: 14px;
            line-height: 156%; 
        }

        h1 {
            font-size: 30px;
            line-height: 35px;
            margin-bottom: 21px;
        }
    }
}

@media(max-width: 960px) {
    .map {
        margin-top: -77px;
        height: 528px;
        padding-top: 64px;
        margin-bottom: 60px;
    }

    .map-info {
        width: 338px;
        padding: 33px;

        &__item {
            margin-bottom: 18px;
        }

        &__btn {
            margin-top: 24px;

            .btn {
                width: 100%;

            }
        }

        h1 {
            margin-bottom: 19px;
        }
    }
}

@media(max-width: 640px) {
    .map {
        height: auto;
        margin-top: -13px;
        padding-top: 0;
        margin-bottom: 48px;

        &__block {
            position: relative;
            left: initial;
            right: initial;
            margin: 0 -32px;
            height: 392px;
        }
    }

    .map-info {
        width: 100%;
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        padding-bottom: 32px;
    }

    .map-info {
        &__btn {
            .btn {
                width: auto;
                padding-left: 38px;
                padding-right: 38px;
            }
        }
    }
}

@media(max-width: 480px) {
    .map {
        margin-top: -6px;
        margin-bottom: 33px;

        &__block {
            height: 240px;
        }
    }

    .map-info {
        &__btn {
            .btn {
                padding-left: 27px;
                padding-right: 27px;
            }
        }
    }
}