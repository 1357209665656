.aside-filter {
   margin-bottom: 72px;
}

@media (max-width: 1440px) and (min-width: 1141px) {
   .aside-filter {
      margin-bottom: 5vw;
   }
}

@media(max-width: 1140px) { 
   .aside-filter {
      display: none;
   }  
}