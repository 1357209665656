.main-service {
    overflow: hidden;
    padding: 0 80px;

    &__item {
        margin-bottom: 72px;

        &:last-child {
            margin-bottom: 0;
        }

        &-name {
            font-weight: bold;
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 16px;    
        }

        &-text {
            font-size: 18px;
            line-height: 156%;
        }
    }

    &__body {
        float: left;
        width: 38.5%;

        &-text {
            font-weight: 500;
            font-size: 18px;
            line-height: 156%;
            margin-bottom: 40px;
        }

        &-title {
            font-weight: bold;
            font-size: 36px;
            line-height: 42px;
            margin-bottom: 20px;
        }
    }

    &__main {
        width: 46.5%;
        float: right;
    }
}

@media(max-width: 1140px) { 
    .main-service {
        padding: 0;

        &__item {
            margin-bottom: 40px;
            
            &-name {
                font-size: 17px;
                line-height: 132%;
                margin-bottom: 12px;
            }

            &-text {
                font-size: 14.25px;
                line-height: 165%;
            }
        }

        &__body {
            width: 50%;
            padding-right: 15px;

            &-title {
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 16px;
            }

            &-text {
                font-size: 14.25px;
                line-height: 165%; 
                margin-bottom: 32px;
            }
        }

        &__main {
            width: 50%;
            padding-left: 15px;
        }
    }
}

@media(max-width: 960px) {
    .main-service {
        &__body {
            display: none;

            &-title {
                order: 1;
                margin-bottom: 45px;
            }

            &-btn {
                margin-top: 40px;
            }

            &-text {
                line-height: 170%;
            }

            &-btn {
                order: 3;
            }
        }

        &__item {
            order: 2;
            margin-bottom: 25px;


        }

        

        &__main {
            width: 100%;
            padding-left: 0;
            display: flex;
            flex-direction: column;
        }
    }
}

@media(max-width: 480px) {
    .main-service {
        &__body {
            &-title {
                margin-bottom: 32px;
            }

            &-btn {
                margin-top: 32px;

                .btn {
                    width: 100%;
                }
            }
        }
    }
}