
.page-block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -32px;

    &--pb {
        padding-bottom: 59px;
    }

    &__aside {
        padding-bottom: 10px;
    }
    
    &__section {
        margin-bottom: 80px;

        h3 {
            margin: 0 0 40px;
        }
    }

    &--full {
        display: block;
        margin: 0;
    }

    &__wrap {
        display: flex;

        .select {
            .jq-selectbox {
                width: 619px;
            }

            .jq-selectbox__select-text {
                font-weight: 500;
            }

            .jq-selectbox__trigger {
                height: 52px;
                width: 52px;
            }
        }

        .btn {
            margin-left: 42px;
            margin-top: 0;

            &--default {
                padding: 13px 32px 12px;
            }
        }
    }


    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 57px;

        h1 {
            margin-top: -8px;
            margin-bottom: 0;
        }
    }


    &--float {
        display: block;
        overflow: hidden;

        .col {
            float: left;
        }
    }

    &--service {
        padding-bottom: 118px;
        
        .page-block {
            &__control {
                width: 342px;
            }

            &__main {
                width: calc(~"100% - 342px");
                padding-left: 94px;
                padding-right: 190px;
                padding-top: 5px;
            }
        }
    }

    &--culture {
        margin-top: -8px;
    }

    &--big {
        .col-25 {
            width: 29.7%;
        }

        .col-75 {
            width: 70.3%;
            padding-right: 53px;
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .page-block {

        &--culture {
            margin-top: 0;
        }

        &--field {
            margin: 0 -2vw;

            .col-25 {
                width: 23%;
                padding: 0 2vw;
            }

            .col-50 {
                width: 54%;
                padding: 0 2vw;
            }
        }

        &__top {
            margin-bottom: 3.2vw;
        }

        &__wrap {
            .select {
                .jq-selectbox {
                    width: 43vw;
                }
            }
        }

        &--service {
            width: 94%;
            margin-left: auto;
            margin-right: auto;

            .page-block {
                &__control {
                    width: 33%;
                }

                &__main {
                    width: 67%;
                    padding-right: 32px;
                    padding-left: 32px;
                }
            }
        }
    }
}

@media(max-width: 1140px) { 
    .page-block {
        margin-left: -12px;
        margin-right: -12px;

        &__top {
            margin-bottom: 43px;
        }

        &__section {
            margin-bottom: 32px;

            h3 {
                margin-bottom: 24px;
            }

            .list-icon {
                span {
                    width: 56px;
                    font-size: 18px;
                    line-height: 156%;
                    text-align: left;
                }

                &--small {
                    .list-icon {
                        &__text {
                            font-size: 14.25px;
                            line-height: 165%;
                            
                        }
                        
                       

                        &__item {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }

        &__wrap {
            .select {
                .jq-selectbox {
                    width: 426px;
                }
            }
        }

        &--culture {
            margin-top: 2px;
        }

        &--big {
            display: block;
            
            .col {
                width: 100%;
                padding-right: 12px;
            }
        }

        &--service {
            .page-block {
                &__control {
                    width: 304px;
                    padding-top: 18px;
                }

                &__main {
                    width: calc(~"100% - 304px");
                    padding-left: 12px;
                    padding-right: 12px;
                }
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .page-block {
        &__section {
            margin-bottom: 5.5vw;
        }
    }
}

@media(max-width: 960px) { 
    .page-block {
        &--catalog {
            display: flex;
            flex-direction: column;
        }

        &--float {
            overflow: visible;

            .col {
                float: none;
            }
        }

        &--culture {
            margin-top: -8px;
        }

        &--service {
            padding-bottom: 40px;

            .page-block {

                &__control {
                    margin-bottom: 49px;
                }

                &__main {
                    width: 100%;
                }
            }
        }

        &--programm {
            display: flex;
            flex-direction: column;



            .page-block {
                &__aside {
                   display: none;
                }

                &__section {
                    margin-bottom: 49px;
                    
                    h3 {
                        font-size: 24px;
                        line-height: 28px;
                    }

                    .list-icon--small {
                        .list-icon {
                            &__item {
                                margin-bottom: 5px;
                            }

                            &__text {
                                font-size: 15px;
                                line-height: 165%;
                            }

                            &__icon {
                                margin-right: 12px;
                            }
                        }
                    }
                 
                }

                &__main {
                    
                }
            }
        }

        &__main {
            width: 100%;
        }

        &__control {
            display: flex;
            width: 100%;
            margin-bottom: 25px;
        }

       

    }
}

@media(max-width: 960px) { 
    .page-block {
        &__wrap {
            .select {    
                width: 264px;

                .jq-selectbox {
                    width: 100%;
                }
            }
        }

        &--culture {
            display: flex;
            flex-direction: column;

            .col-50 {
                float: none;
                width: 100%;
            }
        }
    }
}

@media(max-width: 640px) { 
    .page-block {
        &__top {
            display: block;
            margin-bottom: 30px;
        }

        &__wrap {
            margin-top: 24px;

            .select {
                width: 100%;
            }
        }
    }
}

@media(max-width: 480px) {
    .page-block {
        &--service {
            .page-block {
                &__control {
                    margin-bottom: 40px;
                }
            }
        }

        &--programm {
            .page-block {
                &__section {
                    margin-bottom: 32px;

                    h3 {
                        font-size: 20px;
                        line-height: 23px;
                        margin-bottom: 18px;
                    }

                    .list-icon--small {
                        .list-icon {
                            &__item {
                                display: flex;
                                align-items: center;
                                margin-bottom: 9px;
                            }

                            &__text {
                                text-align: left;
                            }

                            &__icon {
                                margin-right: 12px;
                                margin-left: 0;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}