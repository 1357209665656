.header {
    z-index: 4;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    transition: transform 0.3s ease;
    
    .logo {
        img {
            height: 50px;
        }
    }


    &.is-fixed {
        transform: translateY(-56px);

        .logo {
            img {
                height: 29px;
            }
           
        }

        .city {
            opacity: 0;
            pointer-events: none;
        }

        .header {
            &__bottom {
                .container {
                    height: 48px;
                }
            }
        }
    }
    
    &__top {
        height: 56px;
        background-color: @green;

        &-info {
            display: inline-flex;
            align-items: center;
        }

        &-main {
            height: 100%;
            display: inline-flex;
            align-items: center;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }

        .phone {
            margin-right: 21px;

            .svg-icon {
                display: none;
            }
        }

        .social {
            margin-right: 22px;
        }
    }

    &__bottom {
        background-color: #fff;

        .social {
            display: none;
        }

        .phone {
            display: none;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 88px;
            transition: height 0.3s ease;
        }


        &-info {
            display: inline-flex;
            align-items: center;
            height: 100%;
        }

        &-main {
            display: inline-flex;
            align-items: center;
        }

    }
    
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .header {
        &__top {
            padding-top: 0.8vw;
            padding-bottom: 0.8vw;
            height: auto;

            .phone {
                font-size: 1.4vw;
            }

            &-main {
                .btn {
                    padding: 0.4vw 1.1vw;
                    line-height: 1; 
                }
            }
        }

        &__bottom {
            .container {
                height: 5.5vw;
            }
        }

        .logo {
            width: 13.3vw;

            img {
                height: auto;
            }
        }
    }
}

@media(max-width: 1140px) {
    .header {

        &.is-fixed {
            transform: translateY(0px);

            .city {
                opacity: 1;
                pointer-events: auto;
            }
        }

        &__top {
            display: none;
        }

        &__bottom {
            .logo {
                height: 40px;
                width: auto;

                img {
                    max-height: 100%;
                }
            }

            .container {
                width: 100%;
                padding-left: 25px;
                height: auto;
                padding-right: 0;
            }

            .social {
                display: flex;

                &__item {
                    width: 40px;
                    height: 40px;
                    margin-right: 6px;
                    background-color: @lg1;
                }
            }

            .phone {
                display: inline-flex;
                align-items: center;
                margin-left: 6px;
                background-color: @lg1;
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                padding: 10px 16px 9px 12px;
                border-radius: 20px;
                flex: none;
                color: @green;

                .svg-icon {
                    display: block;
                    margin-right: 12px;
                    flex: none;
                }
            }

            .menu {
                display: none;
            }

            .search-btn {
                display: none;
            }
        }
        
    }
}

@media(max-width: 960px) {
    .header {
        &__bottom {
            .social {
                display: none;
            }
        }
    }
}

@media(max-width: 640px) {
    .header {
       
        &__bottom {
            .phone {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                padding: 0;

                span {
                    display: none;
                }

                .svg-icon {
                    margin-right: 0;
                }
            }

            .logo {
                height: 33px;
            }
    
            .container {
                padding-left: 15px;
            }    
        }
    }
}

@media(max-width: 480px) {
    .header {
        &__bottom {
            .logo {
                height: 27px;
            }

            .phone {
                width: 32px;
                height: 32px;
            }
        }
    }
}