.check {
    display: inline-flex;
    align-items: center;
    margin-bottom: 19px;
    position: relative;

    input {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        cursor: pointer;

        &:checked {
            &~.check {
                &__control {
                    .svg-icon {
                        opacity: 1;

                        use {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__control {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        width: 28px;
        height: 28px;
        background-color: @lg3;
        border-radius: 6px;
        

        .svg-icon {
            opacity: 0.2;
            transition: opacity 0.3s ease;

            use {
                fill: @green;
            }
        }
    }

    &__label {
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        color: #fff;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 32px;
    }

    &--default {
        input:checked~.check__control .svg-icon {
            opacity: 1;

            use {
                fill: @lg3;
            }
        }

        .check {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }

            &__label {
                font-weight: 500;
                font-size: 18px;
                line-height: 156%;
                color: #333234;
            }

            &__control {
                border: 2px solid #BDBDBD;
                background-color: transparent;

                .svg-icon {
                    opacity: 0;

                    use {
                        fill: @lg3;
                       //
                    }
                }
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .check {
        &--default {
            margin-bottom: 1.8vw;

            .check {
           

                &__label {
                    font-size: 1.2vw;
                }

                &__control {
                    width: 1.7vw;
                    height: 1.7vw;
                    border-radius: 4px;
                    border-width: 0.15vw;
                    margin-right: 0.9vw;

                    .svg-icon {
                        width: 1.2vw;
                    }
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .check {
        margin-bottom: 16px;

        &__list {
            margin-bottom: 24px;
        }

        &__label {
            font-size: 18px;
            line-height: 21px;
        }

        &__control {
            width: 24px;
            height: 24px;
            margin-right: 12px;

            .svg-icon {
                width: 12px;
                height: auto;
            }
        }
    }
}

@media(max-width: 480px) {
    .check {
        &__label {
            font-size: 16px;
            line-height: 19px;
        }

        &__control {
            margin-right: 14px;
        }
    }
}