.card-mobile {
    display: none;
}

@media(max-width: 480px) {
    .card-mobile {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 5;
        top: 48px;
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        background: #F4F3F4;
        justify-content: space-between;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;

        &.is-active {
            opacity: 1;
            pointer-events: auto;
        }

        &::before {
            content:" ";
            display: block;
            position: absolute;
            right: 0;
            left: 0;
            top: 100%;
            height: 48px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.11) 0%, rgba(0, 0, 0, 0) 100%);
        }

        &__btn {
            .btn {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.05em;
                padding: 12px 14px;
            }
        }

        &__body {
            display: flex;
            align-items: center;
        }

        &__name {
            font-weight: bold;
            font-size: 17px;
            line-height: 132%;
            color: #333234;
            
        }

        &__type {
            font-size: 13px;
            line-height: 100%;
            color: #BDBDBD;
        }

        &__img {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            margin-right: 16px;
            overflow: hidden;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

@media(max-width: 480px) {

}