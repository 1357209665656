.level {
    &__item {

        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__wrap {
        display: flex;
        align-items: center;
    }

    &__name {
        display: none;
    }

    &__body {
        flex-grow: 1;
        background-color: @lg2;
        border-radius: 24px;
    }

    &__level {
        background-color: @green;
        font-size: 18px;
        line-height: 156%;
        color: #fff;
        padding: 10px 20px;
        border-radius: 24px;
    }

    &__info {
        width: 86px;
        text-align: right;
        flex: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
        color: @lg2;

        span {
            color: @green;
        }
    }
}

@media(max-width: 1140px) { 
    .level {
        &__level {
            padding-top: 12px;
            padding-bottom: 12px;
            font-size: 14.25px;
            line-height: 165%; 
        }

        &__info {
            width: 63px;
            font-size: 14.25px;
            line-height: 165%;
        }
    }
}

@media(max-width: 640px) { 
    .level {
        &__name {
            display: block;
            font-size: 15px;
            line-height: 165%;
            margin-bottom: 2px;
            color: @green;
        }

        &__info {
            width: 63px;
            font-size: 15px;
            line-height: 165%;
        }

        &__item {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
        

        &__level {
            padding-top: 16px;
            padding-bottom: 16px;
            font-size: 0;
        }
    }
}