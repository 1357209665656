.search-main {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    left: 0;
    padding-top: 56px;
    z-index: 7;
    top: 0;
    transition: opacity 0.3s ease;

    &.is-active {
        opacity: 1;
        pointer-events: auto;
    }

    &__body {
        display: inline-flex;
        align-items: center;
    }

    &__input {
        padding-right: 35px;
        flex-grow: 1;

        input {
            font-size: 24px;
            line-height: 132%;
            color: #333234;
            height: 100%;
            border: 0;
            width: 100%;

            &::-webkit-input-placeholder {color: #BDBDBD;}
            &::-moz-placeholder          {color: #BDBDBD;}/* Firefox 19+ */
            &:-moz-placeholder           {color: #BDBDBD;}/* Firefox 18- */
            &:-ms-input-placeholder      {color: #BDBDBD;}
        }
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin-left: 28px;
        cursor: pointer;

        .svg-icon {
            width: 16px;
            height: auto;

            use {
                stroke: #BDBDBD;
            }
        }
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &-block {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 81%;
            height: 88px;
            background-color: #fff;
        }
        
    }
}

@media(min-width: 960px) {
    .search-main {

        &__close {
            &:hover {
                .svg-icon {
                    use {
                        stroke: @green;
                    }
                }
            }
        }
       
    }
}