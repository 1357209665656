.bread {
    margin-bottom: 59px;
    margin-top: 3px;

    &__container {
        position: relative;
        z-index: 2;
    }

    &__back {
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        line-height: 165%; 
        color: #24775A;

        .svg-icon {
            display: block;
            margin-right: 9px;
            margin-top: -3px;

            use {
                fill: #24775A;
            }
        }
    }

    ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            display: inline-flex;
            align-items: center;
            list-style: none;
            font-size: 14px;
            line-height: 100%;
            margin-right: 9px;

            &:last-child {
                margin-right: 0;
            }

            a {
                display: inline-flex;
                align-items: center;
                color: @dark4;

                .svg-icon--back {
                    display: none;
                }

                .svg-icon {
                    margin-left: 10px;
                    
                    use {
                        stroke: @green;
                    }
                }
            }

            span {
                display: inline-flex;
                align-items: center;
                color: @dark4;
            }
        }
    }

    &--white {
        ul {
            li {
                a {
                    color: #fff;

                    .svg-icon {
                        use {
                            stroke: #fff;
                        }
                    }
                }

                span {
                    color: #fff;
                }
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .bread {
        margin-bottom: 4vw;

        ul {
            li {
                a {
                    font-size: 1vw;

                    .svg-icon {
                        margin-left: 0.6vw;
                        width: 0.7vw;
                    }
                }

                span {
                    font-size: 1vw;
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .bread {
        ul {
            li {
                a {
                    &:hover {
                        color: @lg3;
                    }
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .bread {
        margin-bottom: 29px;
        margin-top: 0;

        ul {
            li {
                display: none;

                a {
                    font-size: 12px;
                    line-height: 165%;
                    color: #BDBDBD;

                    .svg-icon {
                        display: none;
                    }

                    .svg-icon--back {
                        display: block;
                        margin-left: 0;
                        margin-right: 8px;
                        margin-top: -2px;

                        use {
                            fill: #BDBDBD;
                            stroke: transparent;
                        }
                    }
                }

                &:nth-last-child(2) {
                    display: inline-flex;
                }
            }
            
        }

        &--white {
            ul {
                li {  
                    a {
                        color: #fff;
                        
                        .svg-icon--back {
                            use {
                                fill: #fff;
                            }
                        }
                    }
                }
                
            }
        }
    }
}

@media(max-width: 960px) { 
    .bread {
        margin-bottom: 40px;
    }
}

@media(max-width: 480px) { 
    .bread {
        margin-bottom: 22px;
    }
}

