.qr {
    margin-bottom: 52px;


    &__img {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        a {
            display: inline-flex;
            align-items: center;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__logo {
        margin-bottom: 12px;

        img {
            display: block;
        }
    }

    &__text {
        font-size: 15px;
        line-height: 160%;
        margin-bottom: 12px;

        a {
            color: @green;
        }
    }
}

@media(max-width: 1140px) {
    .qr {
        &__logo {
            img {
                height: 60px;
            }
        }

        &__img {
            img {
                height: 25px;
            }
        }

        &__text {
            font-size: 12px;
            line-height: 165%;
            margin-bottom: 8px;
        }
    }
}

@media(max-width: 960px) {
    .qr {
        width: 50%;
        padding: 0 15px;

        &__img {
            img {
                height: 32px;
            }
        }

        &__text {
            font-size: 15px;
            line-height: 160%;
            margin-bottom: 12px;
        }

        &__logo {
            img {
                height: 80px;
            }
        }

        &__wrap {
            margin: 0 -15px;
        }
    }
}

@media(max-width: 640px) {
    .qr {
        width: 100%;
        text-align: center;

        &:last-child {
            margin-bottom: 0;
        }

        &__img {
            justify-content: center;

            img {
                height: 39px;
            }
        }

        &__logo {
            display: flex;
            justify-content: center;
        }

        &__wrap {
            flex-direction: column;
        }
    }
}