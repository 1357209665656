.lang {
    display: inline-flex;
    align-items: center;
    background-color: #20694F;
    border-radius: 16px;

    &__item {
        display: inline-flex;
        text-decoration: none;
        padding: 2px 10px 2px 5px;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 156%;
        color: @lg2;
        border-radius: 16px;
        transition: background-color 0.3s, color 0.3s ease;

        &:last-child {
            margin-right: 0;
        }

        &.is-active {
            padding: 2px 7.5px;
            color: #fff;
            background-color: #288564;
        }
    }
}