.item-block {
    display: block;
    color: inherit;

    &--aside {
        margin-bottom: 32px;
        
        .item-block {
            &__img {
                margin-bottom: 0;
            }
        }
    }

    &__slider {
        &.slider-block {
            .slick-list {
                overflow: visible;
                margin: 0 -24px;
            }
        }
       

        .item-block {
            width: 100%;
            opacity: 0.2;
            pointer-events: none;
            transition: opacity 0.3s ease;
        }

        .slick-slide {
            padding: 0 24px;

            &.slick-active {
                .item-block {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }

    &__img {
        height: 292px;
        border-radius: 16px;
        background-position: center;
        background-size: cover;
        margin-bottom: 24px;
        flex: none;
        position: relative;
        overflow: hidden;

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #000;
            opacity: 0.1;
        }
    }

    &__name {
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 8px;
        transition: color 0.3s ease;
    }

    &__text {
        font-size: 15px;
        line-height: 160%;
        color: @dark4;
    }

    &__status {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 15px;
        line-height: 160%;
        padding: 4px 14px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        z-index: 2;
        background: #81324A;
        border-radius: 0px 16px 16px 0px;
    }

    &__slider {
        .slick-slide {
            padding: 0 24px;
        }
    }

    &__info {
        font-size: 18px;
        line-height: 156%;
        margin-top: 16px;
    }

    &__list {
        &>div {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .item-block {
            display: flex;
            margin-bottom: 40px;

            &__body {
                padding-top: 9px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &__name {
                font-size: 22px;
                line-height: 26px;
            }

            &__img {
                width: 200px;
                height: 200px;
                margin-right: 32px;
                margin-bottom: 0;
            }
        }
    }

    &__plit {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -26px -48px;

        .item-block {
            width: calc(~"25% - 52px");
            overflow: hidden;
            margin: 0 26px 48px;
        }
    }
}

@media(min-width: 960px) {
    .item-block {
        &:hover {
            .item-block {
                &__name {
                    color: @green;
                }
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) { 
    .item-block {
        &--aside {
            margin-bottom: 2vw;

            .item-block {
                &__img {
                    height: 18vw;
                    margin-bottom: 0vw;
                }
            }
        }

        &__status {
            font-size: 1vw;
            padding-left: 9px;
            padding-right: 9px;
        }
    }
}

@media(max-width: 1140px) {
    .item-block {
        

        &__img {
            height: 240px;
            margin-bottom: 20px;
        }

        &__text {
            font-size: 12px;
            line-height: 165%;
        }

        &__name {
            font-size: 15px;
            line-height: 132%;
            margin-bottom: 4px;
        }

        &__slider {
            .item-block {
                opacity: 1;
                width: 240px;
            }

            &.slider-block .slick-list {
                margin: 0 -16px;
            }

            .slick-slide {
                padding: 0 16px;
            }
        }

        &__list {
            .item-block {
                align-items: center;
                margin-bottom: 24px;

                &__name {
                    font-size: 17px;
                    line-height: 132%;
                    margin-bottom: 8px;
                }

                &__info {
                    margin-top: 10px;
                    font-size: 14.25px;
                    line-height: 165%;
                }

                &__img {
                    margin-right: 16px;
                    width: 170px;
                    height: 170px;
                }
            }
        }

        &__plit {
            margin-left: -12px;
            margin-right: -12px;
            margin-bottom: -25px;

            .item-block {
                width: calc(~"25% - 24px");
                margin-left: 12px;
                margin-right: 12px;
                margin-bottom: 25px;
                &__img {
                    height: 21vw;

                }
            }
        }
    }
}


@media(max-width: 960px) {
    .item-block {
        &__status {
            font-size: 13px;
            line-height: 160%;
            letter-spacing: 0.02em;
        }

        &--aside {
            .item-block {
                &__img {
                    width: 100%;
                    height: 88vw;
                }
            }
        }

        &__plit {
            margin-left: -12px;
            margin-right: -12px;
            margin-bottom: -25px;

            .item-block {
                width: calc(~"50% - 24px");
                margin-left: 12px;
                margin-right: 12px;
                margin-bottom: 25px;

                &__img {
                    height: 42vw;
                }
            }
        }
    }
}

@media(max-width: 480px) {
    .item-block {

        &__plit {
            margin-bottom: -32px;

            .item-block {
                width: calc(~"100% - 24px");
                margin-left: 12px;
                margin-right: 12px;
                margin-bottom: 32px;

                &__img {
                    height: 85vw;
                    margin-bottom: 16px;
                }
            }
        }
    }
}