.menu-bottom {
    display: flex;
    justify-content: flex-end;

    &__title {
        display: inline-flex;
        margin-bottom: 32px;
        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
        color: #fff;
    }

    &__col {
        margin-right: 105px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            font-size: 16px;
            line-height: 100%;
            color: @dark4;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .menu-bottom {
        justify-content: space-between;

        &__title {
            font-size: 1.2vw;
            margin-bottom: 2.2vw;
        }

        &__body {
            a {
                font-size: 1.1vw;
                margin-bottom: 1.5vw;
            }
        }

        &__col {
            margin-right: 4vw;
        }
    }
}

@media(max-width: 1140px) {
    .menu-bottom {
        justify-content: space-between;

        &__title {
            font-size: 14px;
            line-height: 100%; 
            margin-bottom: 30px;
        }

        &__col {
            margin-right: 0;
        }

        &__body {
            a {
                font-size: 13px;
                line-height: 100%;
                margin-bottom: 16px;
            }
        }
    }
}