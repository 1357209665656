.catalog {
    &__item {
        display: flex;
        align-items: center;
        padding: 32px;
        background-color: #FFFFFF;
        border-radius: 16px;
        color: inherit;
        position: relative;
        overflow: hidden;
        margin-bottom: 32px;
        transition: box-shadow 0.3s ease;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__status {
        display: inline-flex;
       
        padding: 4px 14px;
        border-radius: 0px 16px 16px 0px;
        letter-spacing: 0.02em;
        color: #fff;
        font-weight: bold;
        font-size: 15px;
        line-height: 160%;
       

        &-wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 0px 16px 16px 0px;
            position: absolute;
            left: 0;
            top: 0;
        }

        &--stock {
            background-color: #CA3D67;
        }

        &--new {
            background-color: #4DD678;
        }

        &--spec {
            background-color: #E3517D;
        }

        &--prof {
            background-color: #FEE703;
            color: #333234;
        }
    }

    &__name {
        font-weight: bold;
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 16px;
        transition: color 0.3s ease;
    }

    &__body {
        height: 100%;
        min-height: 256px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-bottom {
            &--mobile {
                display: none;
            }
        }
    }

    &__text {
        font-size: 18px;
        line-height: 156%;   
    }

    &__type {
        font-size: 18px;
        line-height: 156%;
        color: @lightbrown;
        opacity: 0.5;
        margin-bottom: 4px;
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 256px;
        height: 256px;
        margin-right: 56px;
        flex: none;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__tag {
        display: flex;
        flex-wrap: wrap;

        &-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            margin-right: 6px;
        
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        &-text {
            font-size: 18px;
            line-height: 156%;
        }

        &-item {
            display: inline-flex;
            align-items: center;
            background-color: @lightgrey;
            border-radius: 16px;
            padding: 8px 22px 8px 8px;
            margin-right: 12px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__result {
        display: flex;
        flex-wrap: wrap;
        margin-left: -16px;
        margin-right: -16px;

        .catalog-item {
            width: calc(~"25% - 32px");
            margin: 0 16px 48px;
        }
    }
}

@media(min-width: 960px) {
    .catalog {
        &__item {
            &:hover {
                box-shadow: 0px 13px 32px rgba(0, 0, 0, 0.1); 
                
                .catalog {
                    &__name {
                        color: @crimson;
                    }
                }
            }
        }

    }
}

@media(max-width: 1140px) {
    .catalog {
        margin-bottom: -24px;

        &__img {
            width: 200px;
            height: 200px;
            margin-right: 7px;
        }

        &__type {
            font-size: 14.25px;
            line-height: 165%;
        }

        &__text {
            font-size: 14.25px;
            line-height: 165%;
            margin-bottom: 36px;
        }

        &__tag {
            &-item {
                padding-top: 4px;
                padding-left: 4px;
                padding-bottom: 4px;
                padding-right: 14px;
            }

            &-img {
                margin-right: 3px;
            }

            &-text {
                font-size: 14.25px;
                line-height: 165%;
            }
        }

        &__item {
            margin-bottom: 24px;    
        }

        &__body {
            min-height: initial;
        }

        &__name {
            font-size: 30px;
            line-height: 35px;
        }
    }
}

@media(max-width: 960px) { 
    .catalog {
        &__img {
            width: 173px;
            height: 173px;
            margin-right: 18px;
        }

        &__item {
            margin-bottom: 34px;
        }

        &__text {
            margin-bottom: 5px;
        }

        &__type {
            margin-bottom: 2px;
        }

        &__name {
            margin-bottom: 9px;
        }
    }
}

@media(max-width: 640px) { 
    .catalog {
        &__img {
            width: 190px;
            height: 190px;
            margin-right: 0;
        }

        &__body {
            width: calc(~"100% - 190px");
        }

        &__body {
            padding-left: 20px;

            &-bottom {
                display: none;

                &--mobile {
                    margin-top: 12px;
                    display: block;
                }
            }
        }

        &__text {
            font-size: 12px;
            line-height: 165%;
        }

        &__type {
            font-size: 12px;
            line-height: 165%;
        }

        &__name {
            font-size: 17px;
            line-height: 132%;
        }

        &__item {
            flex-wrap: wrap;
            padding-top: 32px;
            padding-left: 24px;
            padding-right: 24px;
            padding-bottom: 24px;
            margin-bottom: 24px;
        }
    }
}

@media(max-width: 480px) { 
    .catalog {
        margin-bottom: 0;
        
        &__item {
            padding-top: 32px;
            padding-left: 27px;
            padding-right: 27px;
            padding-bottom: 32px;
            flex-direction: column;
        }

        &__body {
            width: 100%;
            padding-left: 0;
            text-align: center;
        }

        &__tag {
            margin-bottom: -8px;

            &-img {
                display: none;
            }

            &-item {
                border-radius: 8px;
                padding: 8px;
                margin: 0 8px 8px;
            }
        }

        &__bottom {
            &--mobile {
                display: flex;
                justify-content: center;
                margin-top: 16px;
            }
        }

        &__img {
            margin-bottom: 4px;
        }
    }
}