.mapschema {
   
    
    &__filter {
        margin-bottom: 48px;

        .select {
            margin-bottom: 24px;

            .jq-selectbox__select {
                height: 64px;
                display: flex;
                align-items: center;

            }

            .jq-selectbox__select-text {
                font-weight: 500;
                font-size: 22px;
                line-height: 132%;
            }

            .jq-selectbox__trigger {
                width: 64px;
                height: 64px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__body {
        width: 486px;
        flex: none;
    }

    &__main {
        padding-left: 43px;
        position: relative;

        img {
            display: block;
            width: 100%;
        }

        svg {
            position: absolute;
            left: 43px;
            top: 0;
            width: 100%;
            height: auto;
            max-width: 780px;

            path {
                cursor: pointer;
                fill: transparent;
                transition: fill 0.3s ease;

                &:hover {
                    fill: @lg3;
                }
            }
        }
    }

    &__block {
        display: flex;
        align-items: flex-start;
        margin-bottom: 76px;

        .page-block {
            &__top {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 72px;
                
                h1 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            &__type {
                margin-top: 20px;
                font-weight: bold;
                font-size: 22px;
                line-height: 132%;
                color: #9D7673;
            }
        }
    }

    
}

@media(max-width: 1140px) { 
    .mapschema {
       

        &__main {
            display: none;
        }

        &__filter {
            margin-bottom: 32px;
        }

        &__block {
            margin-bottom: 32px;

            .page-block {
                &__type {
                    margin-top: 8px;
                    max-width: 393px;
                    font-size: 17px;
                    line-height: 132%;
                }

                &__top {
                    margin-bottom: 40px;
                }
            }
        }

        &__body {
            width: 100%;
        }
    }
}

@media(max-width: 960px) {
    .mapschema {

        &__block {
            .page-block {
                &__top {
                    margin-bottom: 32px;
                }
            }
        }

        &__filter {
            margin-bottom: 32px;

            .select {
                margin-bottom: 16px;

                .jq-selectbox__select-text {
                    font-size: 17px;
                    line-height: 165%;
                }

                .jq-selectbox__select {
                    height: 56px;
                }

                .jq-selectbox__trigger {
                    width: 56px;
                    height: 56px;
                }
            }

        }
    }
}

@media(max-width: 640px) {
    .mapschema {

        &__block {
            .page-block {
                &__top {
                    margin-bottom: 32px;
                }

                &__type {
                    font-size: 15px;
                    line-height: 165%;
                    max-width: 293px;
                }
            }
        }

        &__filter {
            margin-bottom: 24px;
            
            .select {
                margin-bottom: 16px;

                .jq-selectbox__select-text {
                    font-size: 17px;
                    line-height: 165%;
                }

                .jq-selectbox__select {
                    height: 46px;
                }

                .jq-selectbox__trigger {
                    width: 46px;
                    height: 46px;
                }
            }
        }
    }
}