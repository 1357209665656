.price-block {
    background: #FFFFFF;
    border-radius: 16px;
    padding: 32px;
    margin-bottom: 48px;

    &--mobile {
        display: none;
    }

    &__item {
        margin-bottom: 16px;
    }
    

    &__title {
        font-size: 15px;
        line-height: 160%;
        color: @dark4;
        margin-bottom: 8px;

        a {
            color: @green;
        }
    }

    &__btn {
        margin-top: 32px;

        .btn {
            width: 100%;
            margin-top: 16px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &__price {
        display: flex;
        align-items: flex-end;
        font-weight: 900;
        font-size: 44px;
        line-height: 100%;
        letter-spacing: -0.03em;

        span {
            margin-left: 4px;
            font-weight: bold;
            font-size: 20px;
            line-height: 140%;
        }
    }
}

@media(max-width: 1140px) {
    .price-block {
        padding: 24px;

        &__title {
            font-size: 12px;
            line-height: 165%;
            margin-bottom: 4px;
        }

        &__item {
            margin-bottom: 12px;
        }

        &__btn {
            margin-top: 28px;
        }

        &__price {
            font-size: 36px;
            line-height: 42px;

            span {
                display: inline-flex;
                font-size: 17px;
                line-height: 156%;
                margin-left: 7px;
                margin-bottom: -1px;  
            }
        }
    }
}

@media(max-width: 960px) {
    .price-block {
        &--mobile {
            display: block;
            padding: 32px;

            .price-block {

                &__item {
                    margin-bottom: 23px;
                }

                &__btn {
                    display: flex;
                    align-items: center;
                    margin: 0 -8px;

                    .btn {
                        font-size: 12px;
                        line-height: 12px;
                        padding-top: 12px;
                        padding-bottom: 12px;
                        width: calc(~"50% - 16px");
                        margin: 0 8px;
                        border-radius: 8px;
                    }
                }

                &__price {
                    font-size: 34px;
                    line-height: 100%;

                    span {
                        font-size: 20px;
                    }
                }

                &__title {
                    font-size: 12px;
                    line-height: 165%;  
                    margin-bottom: 2px;
                }
            }
        }

        &__body {
            display: flex;
            justify-content: space-between;
            padding-right: 24px;
        }

        &__title {
            font-size: 14.25px;
            line-height: 165%; 

            br {
                display: none;
            }
        }

        &__item {
            margin-bottom: 19px;
        }

        &__btn {
            margin-top: -9px;
            .btn {
                font-size: 14px;
                line-height: 16px;
                padding-top: 14px;
                padding-bottom: 14px;
            }
        }

        &__price {
            font-size: 44px;
            line-height: 100%;

            span {
                font-size: 22px;
                line-height: 140%;
                margin-bottom: -1px;
            }
        }
    }
}

@media(max-width: 480px) {
    .price-block {
        &--mobile {
            padding: 24px 16px;
            margin-bottom: 0;

            .price-block {
                &__col {
                    width: 100%;
                }

                &__item {
                    margin-bottom: 17px;
                }

                &__btn {
                    flex-direction: column;
                    margin: 0;

                    .btn {
                        width: 100%;
                        margin: 0;
                        margin-bottom: 12px;
                        
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                &__price {
                   justify-content: center;

                   span {
                        font-size: 22px;
                        margin-left: 5px;
                        line-height: 140%;
                   }
                }
            }
        }

        &__body {
            padding-right: 0;
            flex-direction: column;
            text-align: center;
            margin-bottom: 5px;
        }
    }
}