.select-form {
    margin-bottom: 32px;

    .jq-selectbox {
        width: 100%;

        &.dropdown.opened {
            .jq-selectbox__select {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.opened {
            .jq-selectbox__trigger {
                transform: rotate(180deg);
            }
        }
    }

    .jq-selectbox__dropdown {
        padding: 12px 24px;
        background-color: #fff;
        border-radius: 0 0 12px 12px;
        width: 100%;

        ul {
            margin: 0;
            padding: 0;

            li {
                font-size: 22px;
                line-height: 156%;
                padding: 7px 0;
                transition: color 0.3s ease;

                &.sel {
                    color: @green;
                }
            }
        }
    }

    .jq-selectbox__trigger-arrow {
        display: none;
    }

    .jq-selectbox__select {
        border: 2px solid #fff;
        border-radius: 12px;
        width: 100%;
        transition: border-radius 0.3s ease;
        
    }

    .jq-selectbox__select-text {
        cursor: pointer;
        font-size: 22px;
        line-height: 156%;
        color: #fff;
        padding-left: 24px;
        padding-right: 60px;
        width: 100% !important;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .jq-selectbox__trigger {
        position: absolute;
        right: 0;
        top: 0;
        height: 60px;
        width: 60px;
        background-image: url(../images/common/arrow.png);
        background-position: center;
        background-repeat: no-repeat;
        transition: transform 0.3s ease;
    }
}

@media(max-width: 1140px) { 
    .select-form {
        margin-bottom: 24px;

        .jq-selectbox__select-text {
            font-size: 18px;
            line-height: 156%;
            height: 44px;
            padding-left: 22px;
        }

        .jq-selectbox__trigger {
            height: 44px;
            width: 44px;
        }
    }
}

@media(max-width: 960px) { 
    .select-form {
        margin-bottom: 16px;
    }
}

@media(max-width: 480px) {
    .select-form {
        .jq-selectbox__select-text {
            font-size: 16px;
            line-height: 156%;
        }
    }
}