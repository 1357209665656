.page {
    p {
        color: #333234;

        a {
            color: #24775A;
            text-decoration: underline;
        }
    }

    ul {
        margin: 24px 0;
        padding: 0;

        &:last-child {
            margin-bottom: 0;
        }

        li {
            list-style: none;
            position: relative;
            color: #333234;
            padding-left: 26px;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                content:" ";
                position: absolute;
                background-color: @lightbrown;
                border-radius: 5px;
                top: 9px;
                width: 8px;
                height: 8px;
                left: 0;
                border-radius: 50%;
            }
        }
    }

    &__link {
        display: inline-flex;
        margin-top: 64px;
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
        color: #24775A;
    }

    &__top {
        margin-bottom: 36px;
    }

    &--news-detail {
        max-width: 748px;
        padding-bottom: 100px;

        .img-title {
            font-style: italic;
            font-weight: 300;
            font-size: 16px;
            line-height: 156%;
            margin-top: -30px;
            margin-bottom: 36px;
        }

        img {
            width: 100%;
            margin-bottom: 36px;
        }

        ol {
            margin: 0;
            padding: 0;
            counter-reset: myCounter;

            li {
                list-style: none;
                font-size: 18px;
                line-height: 156%;
                margin-bottom: 20px;
                padding-left: 51px;
                position: relative;

                &::after {
                    content: " ";
                    position: absolute;
                    width: 22px;
                    height: 1.5px;
                    opacity: .3;
                    background-color: #24775a;
                    top: 14px;
                    left: 19px;
                    transform: rotate(-68deg);
                }

                &::before {
                    counter-increment: myCounter;
                    content: "0" counter(myCounter);
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: @green;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 156%;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .video-block {
            width: 100%;
            height: 392px;
            margin-bottom: 48px;
        }

        h4 {
            margin-top: 48px;
            margin-bottom: 16px;
        }
    }

    &__date {
        font-size: 20px;
        line-height: 132%;
        color: #6B6B6B;
    }
}

.red-block {
    color: #CA3D67;
    position: relative;
    padding-left: 32px;
    margin-bottom: 64px;
    margin-top: 36px;

    &::before {
        content:" ";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 16px;
        background: #CA3D67;
    }

}

@media(max-width: 1140px) {
    .page {
        &--news-detail {
    
            .img-title {
                font-size: 14px;
                line-height: 156%;
            }

            ul {
                li {
                    font-size: 15px;
                }
            }

    
            ol {

                li {
                    font-size: 15px;
                    padding-left: 41px;
    
                    &::after {
                        width: 16px;
                        top: 10px;
                        left: 17px;
                    }
    
                    &::before {
                        font-size: 15px;
                    }
    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
  

}

@media(max-width: 960px) {
    .page {
        &--news-detail {
            max-width: 100%;
        }
    }
}

@media(max-width: 480px) {
    .page {
        &--news-detail {
            padding-bottom: 0;
        }
        
        &__link {
            font-size: 15px;
            margin-top: 30px;
        }
    }
}

