.culture {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 35px;

    &__text {
        margin-top: 1px;
        font-size: 15px;
        line-height: 160%;
        color: @green;
    
    }

    &__img {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

    }

    &__icon {
        position: relative;
        margin-bottom: 3px;

        img {
            display: block;
            transition: opacity 0.3s ease;

            &:nth-child(2) {
                left: 0;
                top: 0;
                position: absolute;
                opacity: 0;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        text-align: center;
        padding: 20px;
        width: 240px;
        height: 240px;
        margin-bottom: -35px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        transition: filter 0.3s, background-color 0.3s ease;
     
       

        &:nth-child(3n+3){
            margin-left: 30%;
            margin-right: 30%;
        }

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 2;
            background-color: rgba(0, 0, 0, 0.1);
            transition: background-color 0.3s ease;
        }

        &--info {
            flex-direction: column;
            text-align: center;


            .culture {
                &__name {
                    font-weight: bold;
                    font-size: 22px;
                    line-height: 132%;
                    text-shadow: none;
                    color: #333234;
                }
            }
        }

        &--text {
            .culture {
                &__name {
                    font-size: 22px;
                    line-height: 132%;
                    text-shadow: none;
                }

                &__img {
                    display: none;
                }
            }
        }

        &--green {
            background-color: @lg3;

            &::before {
                display: none;
            }
        }

        &--white {
            background-color: #fff;

            &::before {
                display: none;
            }
        }
    }

    &__name {
        position: relative;
        z-index: 2;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        color: #fff;
        text-shadow: 0px 3px 20px #000000;
        transition: color 0.3s ease;
    }
}

.circle-protection {
    position: absolute;
    z-index: -1;
    top: -12%;
    left: 26%;
    width: 837px;
    height: 837px;
    opacity: 0.5;
    border-radius: 50%;
    border: 175.748px solid #FFFFFF;
    transition: transform 0.3s linear;
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .culture {

        &__icon {
            img {
                width: 5vw;
            }
        }

        &__text {
            font-size: 1vw;
        }

        &__item {
            width: 17vw;
            height: 17vw;
            margin-bottom: -3vw;

            &--info {
                .culture {
                    &__name {
                        font-size: 1.5vw;
                    }
                }
            }

            &--text {
                .culture {
                    &__name {
                        font-size: 1.5vw;
                    }
                }
            }
        }

        &__name {
            font-size: 2vw;
            line-height: 1.2;
        }
    }

    .circle-protection {
        left: 18%;
        width: 662.96px;
        height: 662.96px;
        border: 139.205px solid #FFFFFF;
    }
}

@media(max-width: 1140px) {
    .circle-protection {
        top: -30%;
        left: inherit;
        right: 0;
        width: 736px;
        height: 736px;
        border: 154.541px solid #FFFFFF;
    }

    .culture {
        &__item {
            width: 170px;
            height: 170px;
            margin-bottom: 5px;

            &--text {
                .culture {
                    &__name {
                        font-size: 17px;
                        line-height: 132%;
                    }
                }
            }

            &--info {
                width: 190px;
                height: 190px;

                img {
                    width: 56px;
                }

                .culture {
                    &__name {
                        font-size: 17px;
                        line-height: 132%;
                    }

                    &__text {
                        font-size: 12px;
                        line-height: 165%;
                    }
                }
            }
        }

        &__icon {
            margin-bottom: 9px;
        }

        &__name {
            font-size: 20px;
            line-height: 23px;
        }
    }
}

@media(min-width: 960px) { 
    .culture {
        &__item {
            &:hover {
                filter: drop-shadow(0px 6px 16px rgba(69, 185, 105, 0.2));
            }

            &--white {
                &:hover {
                    filter: none;
                    .culture {
                        &__name {
                            color: @lg3;
                        }

                        &__icon {
                            img {
                                &:nth-child(1) {
                                 
                                }

                                &:nth-child(2) {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }

            &--green {
                &:hover {
                    background-color: @green;
                    
                }
            }
        }
    }
}


@media(max-width: 960px) { 
    .circle-protection {
        top: -36%;
        left: inherit;
        right: -23%;
        width: 618px;
        height: 618px;
        border: 129.764px solid #FFFFFF;
    }

    .culture {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 0;

        &__item {
            width: auto;
            display: inline-flex;
            max-width: 90%;
            border-radius: 143px;
            height: auto;
            padding: 0;
            margin: 0 0 16px;
            background-color: #fff;

            &:nth-child(3n+3) {
                margin: 0 0 16px;
            }

            &--info {
                display: none;
            }

            &::before {
                display: none;
            }

            &--text {
                .culture {
                    &__img {
                        display: flex;
                    }

                    &__name {
                        font-size: 24px;
                        line-height: 28px;
                    }
                }
            }
        }

        &__name {
            font-size: 24px;
            line-height: 28px;
            text-shadow: none;
            color: #333234;
            margin-left: 24px;
            margin-right: 50px;
            text-align: left;
        }

        &__img {
            width: 88px;
            height: 88px;
            flex: none;
            border-radius: 50%;
            position: relative;
            left: initial;
            top: initial;
        }
    }
}

@media(max-width: 640px) {
    .culture {
        &__item {
            max-width: initial;
        }
    }
}

@media(max-width: 480px) {
    .circle-protection {
        top: -34%;
        left: 0;
        right: initial;
    }
    .culture {
        &__img {
            width: 60px;
            height: 60px;
        }

        &__item {
            width: 100%;
            justify-content: flex-start;
            max-width: initial;

            &:last-child {
                margin-bottom: 0;
            }

            &--text {
                .culture {
                    &__name {
                        font-size: 17px;
                        line-height: 132%;
                    }
                }
            }
        }


        &__name {
            font-size: 17px;
            line-height: 132%;
            margin-left: 12px;
        }
    }
}