.banner-aside {
    margin-top: 20px;
    padding-top: 81px;
    position: relative;

    &__img {
        position: absolute;
        pointer-events: none;
        top: 0;
        right: 8px;

        img {
            max-width: initial;
        }
    }

    &__btn {
        margin-top: 16px;
    }

    &__wrap {
        background: linear-gradient(180deg, #45B969 0%, #24775A 76.36%);
        border-radius: 16px;
        padding: 155px 32px 32px;
        text-align: center;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            &.telegramm {
                padding-left: 13px;
                justify-content: flex-start;
            }

            .svg-icon {
                use {
                    fill: #fff;
                }
            }
        }


    }

    &__text {
        font-weight: bold;
        font-size: 22px;
        line-height: 132%;
        color: #fff;
        margin-bottom: 25px;
    }

    &__info {
        font-size: 15px;
        line-height: 160%;
        color: @lg3;
        margin-top: 16px;
    }
}