.search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;

    .svg-icon {
        use {
            fill: @dark;
        }
    }
}

@media(min-width: 960px) {
    .search-btn {
        &:hover {
            .svg-icon {
                use {
                    fill: @green;
                }
            }
        }
    }
}

.search-mobile {
    display: inline-flex;
    padding: 0 13px;
    width: 176px;
    height: 32px;
    background: #CCE5CF;
    border-radius: 16px;
    overflow: hidden;
    margin-top: 24px;
    margin-left: 51px;
    margin-bottom: 15px;

    &__input {
        padding-left: 10px;
        padding-right: 10px;
        border: 0;
        background-color: transparent;
        font-size: 12.6667px;
        color: #24775A;
        font-family: 'Roboto', sans-serif;

        &::-webkit-input-placeholder { font-size: 12.6667px;font-family: 'Roboto', sans-serif;
            color: #24775A;}
        &::-moz-placeholder          { font-size: 12.6667px;font-family: 'Roboto', sans-serif;
            color: #24775A;}
        &:-moz-placeholder           { font-size: 12.6667px;font-family: 'Roboto', sans-serif;
            color: #24775A;}
        &:-ms-input-placeholder      { font-size: 12.6667px;font-family: 'Roboto', sans-serif;
            color: #24775A;}
    }

    &__btn {
        svg {
            display: block;
        }
    }

    form {
        width: 100%;
        position: relative;
        display: inline-flex;
        align-items: center;
    }
}