.page-top {
    margin-bottom: 57px;
    
    &__type {
        font-size: 20px;
        line-height: 132%;
        color: @lightbrown;
        margin-bottom: 8px;
    }

    h1 {
        margin: 0;
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .page-top {
        margin-bottom: 3vw;
        
        &__type {
            font-size: 1.38vw;
            margin-bottom: 0;
            line-height: 1.2;
        }
    }
}

@media(max-width: 1140px) { 
    .page-top {
        margin-bottom: 37px;

        &__type {
            font-size: 18px;
            line-height: 156%;
            margin-bottom: 3px;
        }
    }
}

@media(max-width: 480px) { 
    .page-top {
        margin-bottom: 24px;
        
        &__type {
            font-size: 12px;
            line-height: 165%;
        }
    }
}