.filter-tag {
    margin-top: -15px;
    margin-bottom: 65px;

    &__item {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 8px;
    }

  

    &__control {
        display: flex;
        align-items: center;
        margin-left: 9px;

        .svg-icon {
            opacity: 0.3;
            transition: opacity 0.3s ease;

            use {
                fill: #333234;
            }
        }
    }

    &__tooltip {
        position: absolute;
        pointer-events: none;
        opacity: 0;
        width: 284px;
        padding: 24px;
        font-size: 15px;
        line-height: 160%;
        right: -115px;
        color: @dark4;
        bottom: calc(~"100% + 8px");
        background-color: #FFFFFF;
        box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.21);
        border-radius: 16px;
        z-index: 2;
        transition: opacity 0.3s ease;

        &::before {
            content: " ";
            position: absolute;
            border: 8px solid transparent;
            border-top: 4px solid #fff;
            bottom: -12px;
            right: 0;
            left: 0;
            margin: 0 auto;
            width: 1px;
        }
    }

    &__tag {
        display: flex;
        align-items: center;
        position: relative;
        color: inherit;
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 156%;
        padding: 6px 18px;
        border-radius: 20px;
        margin-right: 16px;
        background-color: #fff;
        transition: color 0.3s ease;

        &.is-active {
            color: #fff;
            background-color: @green;
            
            .filter-tag {
                &__control {
                    .svg-icon {
                        opacity: 3;

                        use {
                            fill: #fff;
                        }
                    }
                }
            }
        }

      /*  &:last-child {
            margin-bottom: 0;
        }*/
    }

    &__title {
        font-size: 18px;
        line-height: 156%;
        width: 120px;
        flex: none;
        padding-bottom: 8px;
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
    }
}

@media (max-width: 1440px) and (min-width: 1141px) { 
    .filter-tag {
        margin-top: 0;
        margin-bottom: 2.3vw;

        &__tag {
            font-size: 1.2vw;
            padding: 0.5vw 1.2vw;
            margin-right: 1.2vw;
            margin-bottom: 1vw;
        }
    }
}

@media(max-width: 1140px)  {
    .filter-tag {
        margin-top: -9px;
        margin-bottom: 38px;

        &__tag {
            padding: 4px 12px;
            font-size: 14.25px;
            line-height: 165%;
            margin-right: 11px;
            margin-bottom: 11px;
        }
    }
}


@media(max-width: 640px)  {
    .filter-tag {
        margin-bottom: 20px;
    }
}


@media(min-width: 960px)  {
    .filter-tag {


        &__tag {
            &:hover {
                .filter-tag {
                    &__control {
                        

                        .svg-icon {
                            opacity: 1;

                            use {
                                fill: @lg3;
                            }
                        }
                    }

                    &__tooltip {
                        opacity: 1;
                    }
                }
            }
        }
    }
}