.card {
    h1 {
        margin: 0 0 3px;
    }

    &__btn {
        display: none;
    }

    .programm {
        &__btn {
            .btn {
                svg {
                    display: none;
                }
            }
        }
    }
    

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @dark3;
        margin-bottom: 32px;
        padding: 11px;
        border-radius: 12px;
    }

    &__info {
        margin-bottom: 64px;

        p {
            margin: 0 0 24px;
        }
    }

    h5 {
        margin-top: 20px;
        margin-bottom: 4px;

        &:first-child {
            margin-top: 0;
        }
    }

    ol {
        padding: 0;
        margin: 8px 0;
       

        li {
            list-style: none;
            font-size: 18px;
            line-height: 156%;
            padding-left: 22px;
            color: @dark4;
            position: relative;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                content: " ";
                position: absolute;
                border-radius: 50%;
                left: 0;
                top: 12px;
                width: 5px;
                height: 5px;
                background-color: @dark3;
            }
        }
    }

    &__top {
        margin-bottom: 19px;
        margin-top: -4px;
    }

    &__type {
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
        color: @dark2;

        &--brown {
            color: @lightbrown;
        }
    }

    &__block {
        margin-bottom: 64px;

        &:last-child {
            margin-bottom: 0;
        }

        &-title {
            margin-bottom: 32px;

            h4 {
                margin: 0;
            }

            h3 {
                margin: 0;
            }
        }

        &-control {
            display: none;

            svg {
                transition: transform 0.3s ease;
            }
        }

        &-body {
            p {
                color: @dark4;
            }
        }
    }

    &__mobile {
        .catalog-item {
            display: none;
        }

        .price-block {
            display: none;
        }
    }

    .video-block {
        width: 100%;
        height: 428px;
    }

    &--diseases {
        h1 {
            margin: 0 0 12px;
        }

        .card {
            &__block {
                margin-bottom: 48px;

                &:last-child {
                    margin-bottom: 0;
                }

                &-title {
                    margin-bottom: 16px;
                }
            }

            &__top {
                margin-bottom: 48px;
                margin-top: 0;
            }

            &__type {
                font-size: 20px;
                line-height: 132%;
            }
        }
    }

    &--field {
        h3 {
            margin-top: 48px;
            margin-bottom: 24px;
        }

        h5 {
            font-weight: bold;
            font-size: 20px;
            line-height: 132%;
            margin-top: 0;
            margin-bottom: 15px;
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .card {
        &--field {
            p {
                font-size: 1.15vw;
            }
            h3 {
                margin-top: 2vw;
                margin-bottom: 0.8vw;
            }

            h5 {
                font-size: 1.25vw;
            }
        }
    }
}

@media(max-width: 1140px) {
    .card {
        ol {
            li {
                font-size: 14.25px;
                line-height: 165%;
            }
        }

        .video-block {
            margin-top: 24px;
            height: 292px;
        }

        &__info {
            max-width: 80%;
            margin-bottom: 41px;
        }

        &__block {
            &-title {
                margin-bottom: 26px;
            }
        }

        &__top {
            margin-bottom: 22px;
        }
    }
}

@media(max-width: 960px) {
    .card {
        display: flex;
        flex-direction: column;    

        &__type {
            font-size: 14.25px;
            line-height: 165%;
        }

        .qr__wrap {
            order: 9;
            margin-top: 60px;
        }

        &__info {
            max-width: 100%;
            
            p {
                margin-bottom: 16px;
                line-height: 170%;
            }
        }

        &__btn {
            display: flex;
            flex-direction: column;
            margin-bottom: 48px;

            .btn {
                padding-top: 14px;
                padding-bottom: 14px;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.05em;
                width: 100%;
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &__block {
            margin: 0 -40px;
            background: linear-gradient(180deg, #FFFFFF 0%, #F4F3F4 100%);
            border-radius: 12px;

            &-body {
                display: none;
                padding-top: 16px;
                padding-left: 40px;
                padding-right: 40px;
                padding-bottom: 32px;
            }

            &-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 40px;
                margin-bottom: 0;

                &.is-active {
                    .card {
                        &__block {
                            &-control {
                                svg {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }

            &-control {
                display: flex;
            }

            /*&-body {
                display: none;
            }*/
        }

        &__mobile {
            display: flex;
            margin-bottom: 39px;

            .radio {
                &__row {
                    margin-bottom: -8px;
                }
            }

            .price-block {
                display: block;
                margin-bottom: 0;
                padding: 0;
                background-color: transparent;

                &__btn {
                    display: none;
                }
            }

            .catalog-item {
                display: block;
                margin-bottom: 16px;

                &--card {
                    .catalog-item {
                        &__wrap {
                            height: 42vw;
                            border-radius: 18px; 
                        }

                        &__img {
                            width: 100%;
                            max-width: 100%;
                            height: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }

            &-info {
                width: 53%;
                padding-left: 24px;
            }

            &-body {
                width: 47%;
            }

            .doc {
                display: none;
            }
        }

        .video-block {
            height: 47vw;
        }

        .programm {
            &__item {
                padding: 16px;
            }
            
            &__name {
                margin-bottom: 8px;
            }

            &__btn {
                margin-top: 20px;

                .btn {
                    font-size: 0;
                    padding: 0;
                    background-color: transparent;

                    svg {
                        display: flex;
                    }
                }
            }
        }

        &--field {
            .doc {
                display: none;
            }
            .card {
                &__block {
                    margin: 0;
                    background: transparent;

                    &-body {
                        display: block;
                        padding: 0;
                        background-color: transparent;
                    }
                }

                &__top {
                    margin-bottom: 32px;

                    h1 {
                        font-size: 24px;
                        line-height: 28px;
                        text-transform: initial;
                    }
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .card {
        .catalog-item {
            &--card {
                .catalog-item {
                    &__wrap {
                        height: 87vw;
                    }
                }
            }
        }

        .item-block {
            width: 240px;
            flex-direction: column;

            

            &__list {
                margin-left: -16px;
                margin-right: -32px;

                .slick-slide {
                    padding: 0 16px;
                }

                .item-block {
                    &__img {
                        width: 240px;
                        height: 240px;
                        margin-bottom: 20px;
                        margin-right: 0;
                    }

                    &__info {
                        display: none;
                    }

                    &__body {
                        padding-top: 0;
                    }

                    &__name {
                        margin-bottom: 4px;
                    }
                }
            }
        }

        &__info {
            margin-bottom: 0;

            p {
                margin-bottom: 26px;
            }
        }

        &__btn {
            margin-bottom: 32px;
        }

        &__block {
           margin-left: -32px;
           margin-right: -32px;
        }
       
        &__mobile {
            display: block;
            margin-bottom: 11px;

            &-info {
                padding-left: 0;
                width: 100%;
            }

            &-body {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        &--field {
            .item-block {
                width: 100%;
            }
        }
    }
}

@media(max-width: 480px) {
    .card {
        &__block {
            margin-left: -24px;
            margin-right: -24px;

            &-title {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}
