.form {
    &__input {
        background: #FFFFFF;
        height: 56px;
        box-shadow: 0px 13px 32px rgba(0, 0, 0, 0.03);
        border-radius: 8px;
        width: 100%;
        outline: 0;
        border: 0;
        padding: 0 24px;
        font-size: 20px;
        line-height: 132%;
        color: #333234;
        font-weight: normal;

        &::-webkit-input-placeholder {color:#BDBDBD;font-weight: normal;}
        &::-moz-placeholder          {color:#BDBDBD;font-weight: normal;}
        &:-moz-placeholder           {color:#BDBDBD;font-weight: normal;}
        &:-ms-input-placeholder      {color:#BDBDBD;font-weight: normal;}
    }

    &__field {
        margin-bottom: 28px;
    }

    &--line {
        .form {
            &__field {
                display: flex;
                align-items: center;
                margin-bottom: 24px;
            }

            &__agree {
                .agree {
                    display: flex;
                    align-items: center;

                    &__text {
                        margin-top: 0;
                        padding-right: 25px;
                    }
                    
                    &__control {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        border: 2px solid #BDBDBD;
                        box-sizing: border-box;
                        border-radius: 6px;
                        margin-right: 11px;
                        flex: none;
                    }
                }
            }

            &__btn {
                display: flex;
                align-items: center;
            }

            &__label {
                width: 220px;
                font-size: 16px;
                line-height: 132%;
                color: #262526;
                flex: none;
            }
        }
    }
}

.form-ok {
    text-align: center;
    display: none;
    &__title {
        margin-top: 8px;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
    }

    &__text {
        margin-top: 8px;
        font-size: 18px;
        line-height: 156%;
    }
}