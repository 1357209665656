.mobile-menu {
    display: none;
}

@media(max-width: 1140px) {
    .mobile-menu {
        display: flex;
        justify-content: flex-end;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 15;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;

        &.is-active {
            opacity: 1;
            pointer-events: auto; 
        }

        &__close {
            position: absolute;
            top: 0;
            right: 0;
            width: 72px;
            height: 72px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                opacity: 0.2;
            }
        }



        &__info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 50px;

            .social {
                display: none;
            }

            .phone {
                display: none;
            }
        }

        &__wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-y: auto;
            overflow-x: hidden;
            height: 100%;
            padding-bottom: 33px;
        }

        &__back {
            position: absolute;
            top: 17px;
            left: 32px;
            opacity: 0.2;
        }

        &__drop {
            top: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            width: 350px;
            background: #24775A;
            z-index: 2;
            transition: transform 0.3s ease;
            transform: translateX(123%);

            &.is-active {
                transform: translateX(0);
            }

            &::before {
                content:" ";
                position: absolute;
                top: 0;
                bottom: 0;
                left: -56px;
                width: 56px;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%);
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }

            &-container {
                height: 100%;
                overflow-y: auto;
                padding: 50px 32px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        list-style: none;
                        margin-bottom: 23px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            font-size: 16px;
                            line-height: 156%;
                            color: @lg1;
                        }
                    }
                }
            }
        }

        

        &__menu {
            .menu-top {
                &>ul {
                    flex-direction: column;
                    align-items: flex-start;

                    &>li {
                        height: auto;
                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &>a {
                            font-size: 16px;
                            line-height: 156%;
                            padding: 0 50px;
                            color: @lg1;
                        }
                    }
                }
            }

            .menu {
                height: auto;
                margin-bottom: 51px;
                margin-right: 0;

                &__control {
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                }

                &>ul {
                    flex-direction: column;
                    align-items: flex-start;

                    &>li {
                        position: relative;
                        width: 100%;
                        height: auto;
                        margin-bottom: 0;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &>a {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            height: 48px;
                            justify-content: space-between;
                            padding: 0 50px;
                            color: @lg1;

                            &.is-active {
                                color: @lg1;
                            }
                        }
                    }
                }
                
            }
        }

        &__block {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 393px;
            height: 100%;
            background-color: #24775A;

            &::before {
                content:" ";
                position: absolute;
                left: -104px;
                top: 0;
                bottom: 0;
                width: 104px;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%);
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }
        }
    }
}