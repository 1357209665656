.acc {
    background-color: #fff;
    border-radius: 9px;
    margin-bottom: 4px;
    

    &__top {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 22px 28px;

        &.is-active {
            .acc {
                &__control {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &__control {
        svg {
            transition: transform 0.3s ease;

            use {
                fill: transparent;
                stroke: #BDBDBD;
            }
        }
    }

    &__body {
        display: none;
        padding: 16px 80px 40px;
    }

    &__name {
        font-weight: bold;
        font-size: 20px;
        line-height: 132%;
        margin-left: 27px;
        transition: color 0.3s ease;
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .acc {
        &__name {
            font-size: 1.4vw;
        }
    }
}

@media (max-width: 1140px) {
    .acc {
        &__top {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}

@media(min-width: 960px) { 
    .acc {
        &__top {
            &:hover {
                .acc {
                    &__name {
                        color: @lg3;
                    }

                    &__control {
                        svg {
                            use {
                                stroke: @lg3;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 640px) { 
    .acc {
        &__name {
            font-size: 15px;
            line-height: 132%;  
            margin-left: 15px;
        }

        &__control {
            display: flex;
            align-items: center;

            svg {
                width: 12px;
                height: auto;
            }
        }

        &__item {
            margin-bottom: 2px;
        }

        &__body {
            padding-left: 57px;
        }

        &__top {
            padding-top: 14px;
            padding-left: 23px;
            padding-bottom: 14px;
        }
    }
}