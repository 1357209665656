.about {

    &__btn {
        margin-top: 20px;
    }

    &__img {
        display: flex;
        align-items: center;
        order: 1;
        width: 600px;
        height: 600px;
        margin-right: 44px;
        position: relative;

        &-small {
            position: absolute;
            top: -193px;
            right: 152px;
        }

        &-middle {
            position: absolute;
            left: -245px;
            top: -89px;
        }

        &-middle-2 {
            position: absolute;
            left: -12px;
            top: -120px;
        }

        &-wrap {
            position: relative;
            background-position: center;
            background-size: cover;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            margin-top: -13px;

            &--small {
                width: 466px;
                height: 466px;
            }
        }
    }
    
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 130px;

        &:nth-child(1) {

        }

        &:nth-child(odd) {
            .about {
                &__body {
                    order: 1;
                }

                &__img {
                    order: 2;
                    margin-left: 57px;
                }
            }
        }

        &:nth-child(even) {
            .about {
                &__body {
                    order: 2;
                }
            }
        }
    }

    &__name {
        font-weight: bold;
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 32px;
    }

    &__body {
        position: relative;
        z-index: 1;
        width: 576px;

        ol {
            margin: 0;
            padding: 0;
            counter-reset: myCounter;

            li {
                list-style: none;
                font-size: 18px;
                line-height: 156%;
                margin-bottom: 20px;
                padding-left: 51px;
                position: relative;

                &::after {
                    content: " ";
                    position: absolute;
                    width: 22px;
                    height: 1.5px;
                    opacity: .3;
                    background-color: #24775a;
                    top: 14px;
                    left: 19px;
                    transform: rotate(-68deg);
                }

                &::before {
                    counter-increment: myCounter;
                    content: "0" counter(myCounter);
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: @green;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 156%;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .about {
        &__img {
            width: 393px;
            height: 100%;
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            margin: 0 !important;

            &-wrap {
                margin-top: 0;
                border-radius: 0;

                &--small {
                    width: 100%;
                    height: 100%;
                }
            }

            &-middle-2 {
                display: none;
            }

            &-middle {
                display: none;
            }

            &-small {
                display: none;
            }
        }

        &__body {
            ol {
                li {
                    font-size: 15px;
                    line-height: 165%;
                }
            }
        }

        &__item {
            position: relative;
            margin-bottom: 0;

            &:nth-child(odd) {
                margin-right: -40px;
                padding-right: 393px;
            }

            &:nth-child(even) {
                margin-right: 0;
                padding-right: 0;
                margin-left: -40px;
                padding-left: 393px;

                .about {
                    &__img {
                        left: 0;
                        right: initial;
                        
                    }

                    &__body {
                        padding-right: 24px;
                        padding-left: 64px;
                    }
                }
            }
        }

        &__body {
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 64px;
            padding-bottom: 64px;
        }

        &__name {
            font-size: 24px;
            line-height: 28px;
        }
    }
}

@media (max-width: 1140px) {
    .about {
        &__item {
            flex-direction: column;
            padding-top: 40px;
            padding-bottom: 40px;
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0 !important;
            }

            &:nth-child(odd) {
                margin: 0;
                padding-left: 0;
                padding-bottom: 0;
                padding-right: 0;
                margin-bottom: 40px;

                .about {
                    &__body {
                        padding: 0;
                        order: 2;
                    } 

                    &__img {
                        order: 1;
                    }
                }
            }

            &:nth-child(even) {
                margin: 0;
                padding-left: 0;
                padding-bottom: 0;
                padding-right: 0;
                margin-bottom: 40px;

                .about {
                    &__body {
                        padding: 0;
                        order: 2;
                    } 

                    &__img {
                        order: 1;
                    }
                }
            }
        }

        &__body {
            width: auto;
            padding: 0;
            order: 2;
        }

        &__name {
            margin-bottom: 24px;
        }

        &__img {
            width: 100%;
            order: 1;
            height: 65vw;
            position: relative;
            margin-bottom: 32px !important;
        }
    }
}

@media (max-width: 640px) {
    .about {
        &__title {
            font-size: 24px;
            line-height: 28px;
        }
    }
}