.file-big {
    padding: 16px;
    font-size: 18px;
    line-height: 132%;
    position: relative;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 36px;
    border: 1px dashed #24775A;
    border-radius: 8px;

    input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 2;
        opacity: 0;
    }

    &__text {
        text-align: center;

        span {
            color: #24775A;
        }
    }
}