.banner-text {
    position: relative;
    padding-top: 94px;
    padding-bottom: 220px;

    &__img {
        position: absolute;
        width: 100vw;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
    }

    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
        color: #FFFFFF;
        max-width: 757px;
        margin: 0 auto 40px;
        position: relative;
        text-align: center;
        z-index: 3;
    }

    &__btn {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 3;
    }

    &__title {
        font-weight: bold;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        margin-bottom: 24px;
        color: #FFFFFF;
        position: relative;
        max-width: 757px;
        margin: 0 auto 24px;
        z-index: 3;
    }

    &::before {
        content:" ";
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 100%);
    }
}