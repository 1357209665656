.planet {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__body {
        width: 50%;
        flex: none;
    }

    &__img {
        flex: none;

    }

    &__text {
        font-weight: bold;
        font-size: 44px;
        line-height: 52px;
        margin-bottom: 40px;
        color: #fff;

        span {
            display: block;
            color: @lg3;
        }
    }

    &__textmini {
        font-weight: bold;
        font-size: 28px;
        line-height: 132%;
        color: #fff;
    }

    &__subtitle {
        font-weight: bold;
        font-size: 20px;
        line-height: 132%;
        color: @lg3;
        margin-bottom: 16px;  
    }
}

@media(max-width: 1140px) { 
    .planet {
        &__img {
            width: 326px;
            height: 326px;
            flex: none;
        }

        &__text {
            font-size: 30px;
            line-height: 35px;
            margin-bottom: 32px;
        }

        &__body {
            width: calc(~"100% - 326px");
            padding-left: 52px;
        }
    }
}

@media(max-width: 960px) { 
    .planet {
        display: block;

        &__img {
            margin-bottom: 48px;
        }

        &__body {
            padding-left: 0;
            width: 100%;
        }

        &--inner {
            .planet {
                &__subtitle {
                    font-size: 15px;
                    line-height: 132%;
                }

                &__img {
                    display: none;
                }

                &__textmini {
                    font-size: 20px;
                    line-height: 150%;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .planet {
        &__text {
            margin-bottom: 28px;
        }

        &--inner {
            .planet {
                &__text {
                    font-size: 16px;
                    line-height: 150%;
                }
            }
        }
    }
}

@media(max-width: 480px) {
    .planet {
        &__img {
            width: 232px;
            height: 232px;
            margin: 0 auto 24px;
        }

        &__btn {
            display: flex;
            justify-content: center;
        }

        &__text {
            font-size: 20px;
            line-height: 137%;
            text-align: center;
        }
    }
}