.banner {
    display: flex;
    align-items: center;
    height: 560px;
    position: relative;
    background-position: center;
    background-size: cover;

    &__video {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    &__body {
        max-width: 577px;
    }

    &__subtitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        padding-top: 2px;
        padding-bottom: 4px;

        svg {
            position: absolute;
            left: 0;
            top: 0;
        }


        span {
            padding: 1px 2px 1px 15px;
            position: relative;
            z-index: 1;
            font-size: 22px;
            line-height: 150%;
            color: #fff;
            display: inline-flex;
        }
    }

    &__title {
        margin-top: 32px;
        font-weight: bold;
        font-size: 44px;
        line-height: 52px;
        color: #fff;
    }

    &__form {
        width: 404px;
        background: linear-gradient(180deg, #45B969 0%, rgba(69, 185, 105, 0.6) 0.01%, rgba(69, 185, 105, 0.4) 100%);
        backdrop-filter: blur(28px);
        border-radius: 24px;
        flex: none;
        position: relative;
        padding: 53px 47px;

        .btn {
            width: 100%;
        }
    }

    &::before {
        content:" ";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.63) 0%, rgba(0, 0, 0, 0) 100%);
    }
}

@media(max-width: 1140px) {
    .banner {
        height: 456px;

        &__body {
            padding-right: 30px;
            padding-bottom: 25px;
        }

        .container {
            width: 84%;
        }

        &__subtitle {
            svg {
                height: 60px;
                width: auto;
            }

            span {
                font-size: 17px;
                line-height: 150%;
                padding-left: 11px;
            }
        }

        &__title {
            font-size: 30px;
            line-height: 35px;
            margin-top: 23px;
        }
        
        &__form {
            width: 352px;
            padding: 40px 32px;
            background: rgba(69, 185, 105, 0.24);
            backdrop-filter: blur(26.1262px);
        }
    }
}

@media(max-width: 960px) { 
    .banner {
        height: auto;
        padding-top: 44px;

        form {
            display: flex;
            align-items: flex-start;

            .check__list {
                width: 50%;
                margin-top: 4px;
                margin-bottom: 0;
            }
        }

        &__form {
            padding: 39px 40px;
            width: 100%;
            background: rgba(69, 185, 105, 0.24);
            backdrop-filter: blur(26.1262px);
            border-radius: 0;

            &-wrap {
                width: 50%;
                padding-left: 11px;
            }
        }

        &__title {
            margin-top: 16px;
        }

        &__body {
            padding: 0 40px 49px;
            max-width: 100%;
        }

        .container {
            width: 100%;
            padding: 0;
            flex-direction: column;
        }
    }
}

@media(max-width: 640px) { 
    .banner {
        &__body {
            padding-left: 32px;
            padding-right: 32px;
        }

        &__form {
            padding: 32px 32px 46px;

            &-wrap {
                width: 100%;
                padding: 0;
            }
        }

        form {
            display: block;

            .check__list {
                margin-top: 0;
                width: 100%;
                margin-bottom: 24px;
            }
        }
    }
}

@media(max-width: 480px) {
    .banner {
        &__title {
            font-size: 24px;
            line-height: 28px;
        }

        &__form {
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: 32px;
        }

        &__subtitle {
            svg {
                height: 41px;
            }

            span {
                font-size: 12px;
                line-height: 148%;
                padding-left: 8px;
            }
        }

        &__body {
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: 30px;
        }
    }
}