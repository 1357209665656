.record {
    &__item {
        display: flex;
        color: inherit;
        border-radius: 16px;
        box-shadow: 0px 13px 32px rgba(0, 0, 0, 0.03);
        overflow: hidden;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__wrap {
        display: flex;
    }

    &__body {
        background-color: #fff;
        height: 280px;
        padding: 32px;
    }

    &__text {
        font-size: 18px;
        line-height: 156%;
    }

    &__btn {
        margin-top: 23px;
    }

    &__type {
        font-size: 15px;
        line-height: 160%;
        color: @lightbrown;
        margin-bottom: 8px;
    }

    &__name {
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 4px;
    }

    &__img {
        width: 280px;
        height: 280px;
        flex: none;
        border-radius: 16px;
        background-position: center;
        background-size: cover;
    }
}