.banner-service {
    position: relative;
    background-position: 50%;
    background-size: cover;
    margin-top: -102px;
    padding-top: 91px;
    padding-bottom: 111px;
    margin-bottom: 96px;

    &::before {
        content:" ";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.63) 0%, rgba(0, 0, 0, 0) 100%);
        z-index: 1;
    }

    &__title {
        font-weight: bold;
        font-size: 68px;
        line-height: 80px;
        color: #fff;
        margin-bottom: 16px;
    }

    &__text {
        color: #F4F3F4;
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
    }

    &__wrap {
        max-width: 544px;
        position: relative;
        z-index: 2;
        margin-left: 79px;
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .banner-service {
        margin-top: -7vw;
        padding-top: 6.2vw;
        padding-bottom: 7.8vw;
        margin-bottom: 6.2vw;

        &__wrap {
            margin-left: 6.5%;
            max-width: 40%;
        }

        &__title {
            font-size: 4.65vw;
            line-height: 1.2;
        }

        &__text {
            font-size: 1.2vw;
        }
    }
}

@media (max-width: 1140px) {
    .banner-service {
        padding-top: 151px;
        margin-top: -67px;
        padding-bottom: 117px;
        margin-bottom: 56px;

        &__text {
            font-size: 15px;
            line-height: 165%; 
        }

        &__wrap {
            max-width: 466px;
            margin-left: 0;
        }

        &__title {
            font-size: 53px;
            line-height: 62px;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 960px) {
    .banner-service {
        margin-top: -77px;
        padding-top: 104px;
        padding-bottom: 91px;
        margin-bottom: 35px;

        &__title {
            font-size: 46px;
            line-height: 54px;
        }
    }
}

@media(max-width: 640px) { 
    .banner-service {
        padding-top: 86px;
        padding-bottom: 62px;

        &__title {
            font-size: 30px;
            line-height: 35px;
        }
    }
}

@media(max-width: 480px) {
    .banner-service {
        padding-top: 73px;
        padding-bottom: 50px;
        margin-bottom: 26px;
    }
}