.career {
    position: relative;
    z-index: 2;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__body {
        padding-right: 164px;
    }

    &__city {
        font-size: 18px;
        line-height: 156%;
        color: @lightbrown;
        opacity: 0.6;
        margin-bottom: 16px;
    }

    &__btn {
        flex: none;
    }

    &__text {
        font-size: 18px;
        line-height: 156%;
    }

    &__item {
        display: inline-flex;
        max-width: 1152px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: space-between;
        color: inherit;
        padding: 48px;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__name {
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 4px;
    }
}

@media(max-width: 1140px) {
    .career {
        &__item {
            max-width: initial;
            width: 100%;
            padding: 32px;
            border-radius: 8px;
        }

        &__body {
            padding-right: 50px;
        }

        &__title {
            font-size: 24px;
            line-height: 28px;      
            margin-bottom: 2px;
        }

        &__text {
            font-size: 15px;
            line-height: 165%;
        }

        &__city {
            font-size: 15px;
            line-height: 165%;
            margin-bottom: 6px;
        }
    }
}

@media(max-width: 960px) {
    .career {
        &__title {
            font-size: 20px;
            line-height: 23px;
        }

        &__item {
            margin-bottom: 0;
            border-radius: 0;
        }

        &__body {
            padding-right: 0;
            margin-bottom: 22px;
        }

        &__item {
            display: block;
        }
    }
}

@media(max-width: 640px) {
    .career {
        &__title {
            font-size: 20px;
            line-height: 23px;
        } 
    }
}

@media(max-width: 480px) { 
    .career {
        margin-bottom: -50px;

        .container {
            padding: 0;
        }

        &__name {
            font-size: 20px;
            line-height: 23px;   
        }

        &__item {
            padding: 24px;

            &:last-child {
                padding-bottom: 48px;
            }
        }
    }
}