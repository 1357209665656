.section {
    padding-top: 63px;
    padding-bottom: 63px;

    &--about {
        position: relative;
        z-index: 1;
        background: #f4f3f4;
        padding-top: 103px;
    }

    &__btn {
        display: none;
    }

    &--big {
        padding-top: 140px;
        padding-bottom: 157px;
    }

    &--white {
        background-color: #fff;
    }

    &--grey {
        background: #F4F3F4;
    }

    &--dark {
        background-color: #262526;
    }

    &--planet {
        padding: 0;
        position: relative;
        z-index: 2;
        overflow: hidden;

        .section {
            &__body {
                position: relative;
            }
        }

        .container {
            padding-top: 93px;
            padding-bottom: 93px;
            position: relative;
        }

        .circle-big {
            top: -54px;
            left: 246px;
            position: absolute;
            transition: transform 0.6s ease;
        }

        .circle { 
            top: 418px;
            right: 90px;
            border-radius: 50%;
            position: absolute;
            width: 260px;
            height: 260px;
            opacity: 0.3;
            border: 54.5932px solid #24775A;
            transition: transform 0.6s ease;
        }
    }

    &--video {
        padding-top: 124px;
        padding-bottom: 124px;
    }

    &--client {
        padding-top: 151px;
        padding-bottom: 101px;
    }

    &--culture {
        padding-top: 0;
        margin-top: -119px;
        position: relative;
        z-index: 3;
    }

    &--main-service {
        padding-top: 146px;
        padding-bottom: 159px;
    
        .section {
            &__body {
                position: relative;
            }
        }


        .container {
            position: relative;
        }

        .circle {
            width: 967px;
            height: 967px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: -186px;
            border: 203.045px solid #FFFFFF;
            transition: transform 1s ease;
            transition-delay: 0.5s;
            transform: scale(0);
        }

        .main-service {
            &__body {
                &-wrap {
                    transform: translateY(250px);
                    opacity: 0;
                    transition: opacity 1s, transform 1s ease;
                }
             
            }
        }

        &.is-active {
            .circle {
                transform: scale(1);
            }

            .main-service {
                &__body {
                    &-wrap {
                        transform: translateY(0px);
                        opacity: 1;
                    }
                }
            }
        }
    }

    &--last {
        padding-bottom: 121px;
    }

    &--pb0 {
        padding-bottom: 0 !important;
    }

    &--pt0 {
        padding-top: 0 !important;
    }

    &--solution {
        padding-bottom: 142px;
    }

    &--inner {
        padding-top: 93px;
        padding-bottom: 113px;
    }

    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 63px;

        &--small {
            margin-bottom: 48px;
        }

        .btn {
            margin-left: 32px;
        }

        h2 {
            margin: 0;
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .section {
        &__top {
            &--small {
                margin-bottom: 3vw;
            }
        }
    }
}

@media(max-width: 1140px) { 
    .section {
        &__top {
            margin-bottom: 40px;

            .btn {
                margin-left: 24px;
            }
        }

        &--about {
            padding-top: 0 !important;
        }

        &--video {
            padding-top: 75px;
            padding-bottom: 73px;
        }

        &--solution {
            padding-bottom: 59px;
        }

        &--last {
            padding-bottom: 84px;
        }

        &--client {
            padding-top: 68px;
            padding-bottom: 10px;
        }

        &--planet {
            .container {
                padding-top: 116px;
                padding-bottom: 110px;
            }

            .circle {
                top: 300px;
                right: 123px;
                width: 205.83px;
                height: 205.83px;
                border: 43.2196px solid #24775A;
            }

            .circle-big {
                width: 369px;
                height: 369px;
                left: 162px;
                top: -17px;

                svg {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        &--main-service {
            padding-top: 74px;
            padding-bottom: 79px;

            .circle {
                width: 765.54px;
                height: 765.54px;
                border: 160.744px solid #FFFFFF;
                right: 0;
                margin: 0 auto;
            }
        }
    }
}

@media(max-width: 960px) { 
    .section {
        &__top {
            margin-bottom: 45px;
        }

        &--planet {
            .container {
                padding-top: 64px;
                padding-bottom: 64px;
            }
        }

        &--main-service {
            padding-top: 64px;
            padding-bottom: 64px;
        }

        &--video {
            padding-top: 48px;
            padding-bottom: 46px;
        }

        &--solution {
            padding-bottom: 63px;
        }

        &--big {
            padding-top: 66px;
            padding-bottom: 104px;
        }
    }
}   

@media(max-width: 640px) {
    .section {
        &--video {
            padding-bottom: 0;
        }
    }
}

@media(max-width: 640px) {
    .section {
        padding-top: 50px;
        padding-bottom: 50px;

        &__top {
            margin-bottom: 25px;
        }

        &--planet {
            .container {
                padding-bottom: 58px;
            }
        }
    }
}

@media(max-width: 480px) {
    .section {
        &__top {
            display: block;
            margin-bottom: 25px;

            .btn {
                display: none;
            }
        }

        &__btn {
            margin-top: 40px;
            display: flex;

            .btn {
                width: 100%;
            }
        }

        &--solution {
            padding-bottom: 60px;
        }

        &--main-service {
            padding-top: 56px;
            padding-bottom: 56px;

            .circle {
                display: none;
            }
        }

        &--planet {
            .circle-big {
                display: none;
            }

            .circle {
                display: none;
            }

            .container {
                padding-top: 48px;
                padding-bottom: 48px;
            }
        }
    }
}