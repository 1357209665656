.menu-protect {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -48px;

    &__wrap {
        width: 25%;
        margin-bottom: 48px;
    }

    &__item {
        display: inline-flex;
        align-items: center;
    }

    &__img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin-right: 16px;
        flex: none;
    }

    &__name {
        font-weight: 500;
        font-size: 18px;
        line-height: 132%; 
        color: #333234;
        max-width: 72%;
        transition: color 0.3s ease;
    }
}

@media(min-width: 960px) {
    .menu-protect {
        &__item {
            &:hover {
                .menu-protect {
                    &__name {
                        color: @green;
                    }
                }
            }
        }
    }
}