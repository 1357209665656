.doc {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    line-height: 120%;
    color: @green;

    &__icon {
        display: flex;
        margin-right: 14px;

        svg {
            display: block;
        }
    }

    &__name {
        transition: border-color 0.3s ease;
        border-bottom: 2px solid transparent;
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .doc {
        &__icon {
            width: 2vw;
            margin-right: 1vw;
            
            svg {
                display: block;
                height: auto;
            }
        }

        &__name {
            font-size: 1.1vw;
        }
    }

}

@media(min-width: 960px) {
    .doc {
        &:hover {
            .doc {
                &__name {
                    border-color: @green;
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .doc {
        &__name {
            font-size: 15px;
            line-height: 165%;
        }
    }
}

@media(max-width: 960px) {
    .doc {
        &__icon {
            width: 22px;
            margin-right: 14px;
        }

        &__name {
            font-size: 15px;
            line-height: 165%;
        }
    }
}