.block-info {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    &__col {
        width: 33.3%;
        padding: 0 20px;
    }

    &__item {
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FEE703;
        margin-right: 12px;
        border-radius: 50%;
        width: 32px;
        height: 32px;
    }

    &__type {
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
        margin-left: 8px;
        color: #9D7673;
    }

    &__name {
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
    }

    &__title {
        margin-bottom: 32px;
        font-weight: bold;
        font-size: 22px;
        line-height: 132%;
    }
}

@media(max-width: 1140px) { 
    .block-info {
        margin-left: -12px;
        margin-right: -12px;

        &__title {
            font-size: 17px;
            line-height: 132%;
            margin-bottom: 16px;
        }

        &__item {
            margin-bottom: 16px;
        }

        &__top {
            margin-bottom: 8px;
        }

        &__text {
            font-size: 15px;
            line-height: 165%;
        }

        &__name {
            font-size: 15px;
            line-height: 165%;
        }

        &__type {
            font-size: 15px;
            line-height: 165%;
        }

        &__logo {
            width: 32px;
            height: 32px;
        }

        &__col {
            padding: 0 12px;
        }
    
    }
}

@media(max-width: 960px) {
    .block-info {
        margin-left: 0;
        margin-right: 0;
        
        &__title {
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 8px;
        }

        &__col {
            width: 100%;
            padding: 0;
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}