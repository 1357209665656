.prod-info {
    display: inline-flex;
    align-items: center;

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        font-weight: 900;
        font-size: 18px;
        line-height: 160%;
        font-family: 'Inter', sans-serif;
        border-radius: 50%;
        margin-right: 10px;
        flex: none;
    }

    &__name {
        font-weight: bold;
        font-size: 16px;
        color: #333234;
        line-height: 120%;
        transition: color 0.3s ease;
    }

    &__type {
        margin-top: 4px;
        font-size: 13px;
        line-height: 100%;
        color: @dark2;
        transition: color 0.3s ease;
    }
}

@media(min-width: 960px) {
    .prod-info {
        &:hover {
            .prod-info {
                &__name {
                    color: @green;
                }

                &__type {
                    color: @green;
                }
            }
        }
    }
}