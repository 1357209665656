.phone {
    display: inline-flex;
    font-weight: 500;
    font-size: 20px;
    line-height: 156%;
    color: @lg2;

    &__title {
        font-size: 15px;
        line-height: 160%;
        color: @dark4;
        margin-bottom: 8px;
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .phone {
        &__block {

        }

        &__title {
            font-size: 1vw;
        }
    }
}

@media(max-width: 1140px) {
    .phone {
        &__block {
            .phone {
                &__title {
                    font-size: 13px;
                    line-height: 160%;
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .phone {
        &__block {
            .phone {
                &__title {
                    color: #FFFFFF;
                }
            }
        }
    }
}