* {
    box-sizing: border-box;
}

.clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .modal-footer:after, .modal-footer:before, .row:after, .row:before {
    content: " ";
    display: table;
}

.clearfix:after, .container-fluid:after, .container:after, .dl-horizontal dd:after, .modal-footer:after, .row:after {
    clear: both;
}

::marker {
    display: none;
}

html, body {
    height: 100%;
}

body  {
    height: auto;
    min-height: 100%;
    color: @dark3;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    font-size: 18px;
    line-height: 156%;

    &.is-hidden {
        overflow: hidden;
    }

    &.is-index {

    }
}

p {
    font-size: 18px;
    line-height: 156%;
    margin: 0 0 20px;
}

@media(max-width: 1140px) {
    p {
        font-size: 15px;
        line-height: 165%;
    }
}

input, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input, a, button, .slick-slide, textarea {
    outline: 0;
}

a {
    transition: color 0.3s ease;
    text-decoration: none;

    &:focus {
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }
}

img {
    max-width: 100%;
    display: block;
}

textarea {
    resize: none;
}

.svg-icon {
    use {
        transition: fill 0.3s, stroke 0.3s ease;
    }
}

a {
    transition: color 0.3s ease;
}



.col-1 {
    width: 25%;
}

.col-3 {
    width: 75%;
}


.small-title {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
}

@media(max-width: 960px) {
    .hide-mobile-960 {
        display: none !important;
    }
}

.ymaps-layers-pane {
    -webkit-filter: grayscale(100%);
}

.col {
    width: 100%;
    padding: 0 32px;

   
}


.col-25 {
    width: 25%;
    padding: 0 32px;
}

.col-50 {
    width: 50%;
    padding: 0 32px;
}

.col-75 {
    width: 75%;
    padding: 0 32px;

    &--programm {
        padding-right: 94px;
    }
}

@media(max-width: 1140px) { 
    .col {
        padding: 0 12px;
    }

    .col-25 {
        padding: 0 12px;
    }

    .col-75 { 
        padding: 0 12px;
    }

    .col-50 {
        padding: 0 12px;
    }
}

@media(max-width: 1140px) {
    .col-100-1140 {
        width: 100%;
    }
}


@media(max-width: 960px) {
    .col-100-960 {
        width: 100%;
    }
}



.circle-programm {
    position: fixed;
    width: 837px;
    height: 837px;
    opacity: 0.5;
    border-radius: 50%;
    border: 175.748px solid #FFFFFF;
}

.img-aside {
    border-radius: 16px;
    height: 280px;
    background-position: center;
    background-size: cover;
    margin-bottom: 32px;
    overflow: hidden;
}

.btn-more {
    margin-top: 121px;

    a {
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
        color: @green; 
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .btn-more {
        margin-top: 8vw;

        a {
            font-size: 1.25vw;
        }
    }
}

@media(max-width: 1140px) { 
    .btn-more {
        margin-top: 32px;

        a {
            font-size: 14.25px;
            line-height: 165%;
        }
    }
}


@media(max-width: 960px) { 
    .btn-more {
        margin-top: 24px;
    }
}

.telegramm {
    background-color: #3EC4FF;
}

.whatsapp {
    background-color: #3FD458;
}

.viber {
    background-color: #B569F1;
}

.is-yellow {
    background-color: #FEE703;
}

.is-green {
    background-color: #2AA64E;
}

.is-darkgreen {
    background-color:#24775A;
}

.is-white-text {
    color: #fff;
}


.hidden-desc {
    display: none !important;
}


@media(max-width: 1140px) {
    .hidden-mobile-1140 {
        display: none;
    }

}

@media(max-width: 960px) {
    .hidden-desc {
        display: flex !important;
    }

    .hidden-mobile {
        display: none !important;
    }

}