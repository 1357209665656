.it {
    margin-top: 48px;

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__img {
        display: flex;
        align-items: center;
        
        a {
            display: inline-flex;

            margin-right: 12px;

            &:last-child {
                margin-right: 0;
            }
        }

        img {
            display: block;
        }
    }

    &__name {
        font-weight: bold;
        font-size: 22px;
        line-height: 132%;
        margin-bottom: 12px;
    }

    &__text {
        margin-bottom: 24px;

        span {
            color: @green;
        }
    }

    &__logo {
        width: 160px;
        margin-left: 32px;
        flex: none;

        img {
            max-width: 100%;
        }
    }
}

@media(max-width: 1140px) { 
    .it {
        &__name {
            font-size: 17px;
            line-height: 132%;
        }

        &__logo {
            width: 100px;
            height: 100px;
        }

        &__text {
            font-size: 15px;
            line-height: 165%;
        }
    }
}

@media(max-width: 640px) {  
    .it {
        margin-top: 34px;

        &__logo {
            width: 80px;
            height: 80px; 
        }
    }
}

@media(max-width: 480px) { 
    .it {
        &__item {
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin-bottom: 52px;
        }

        &__logo {
            width: 100px;
            height: 100px;
            margin-left: 0;
            margin-bottom: 12px;
        }

        &__img {
            justify-content: center;

            a {
                margin: 0 3px;

                &:last-child {
                    margin-right: 3px;
                }
            }
        }

        &__body {
            order: 2;
        }

        &__logo {
            order: 1;
        }
    }
}