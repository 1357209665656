.review {
    &__body {
        background-color: #fff;
        padding: 33px 40px 33px;
        font-style: italic;
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
        border-radius: 24px;
    }

    &__name {
        font-weight: bold;
        font-size: 20px;
        line-height: 132%;
    }

    &__info {
        display: flex;
        align-items: center;
        padding-left: 20px;
        margin-top: -16px;
    }

    &__post {
        font-size: 15px;
        line-height: 160%;
        color: @dark4;
        margin-top: 4px;
    }

    &__avatar {
        width: 112px;
        height: 112px;
        margin-right: 19px;
        flex:  none;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
    }

    &__slider {

        &.slider-block {
            .slick-list {
                margin: 0 -45px;
            }

            .slick-arrow {
                &.slick-next {
                    right: initial;
                    left: 872px;
                }
            }

            .slick-current {
                .review {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
     
        .review {
            width: 815px;
            margin: 0 45px;
            opacity: 0.2;
            pointer-events: none;
            transition: opacity 0.3s ease;
        }
    }
}

@media(max-width: 1140px) {
    .review {
        &__body {
            font-size: 14.25px;
            line-height: 169%;
            padding: 57px 40px;
        }

        &__info {
            padding-left: 60px;
            margin-top: -21px;

            &-body {
                padding-top: 27px;
            }
        }

        &__avatar {
            width: 80px;
            height: 80px;
            margin-right: 10px;
        }

        &__name {
            font-size: 15px;
            line-height: 132%;
        }

        &__post {
            font-size: 12px;
            line-height: 165%;
        }
    }
}

@media(max-width: 960px) { 
    .review {
        &__slider {
            .review {
                width: 560px;
                margin: 0;
            }
        }

        &__body {
            color: #262526;
            padding: 40px;
            font-size: 14.25px;
            line-height: 165%;
        }
    }
}

@media(max-width: 640px) { 
    .review {
        &__slider {
            .review {
                width: 382px;

                &__info {
                    margin-top: 0;
                    padding-top: 0;
                    padding-bottom: 32px;
                    background-color: #fff;
                    padding-left: 32px;
                    padding-right: 32px;
                    border-bottom-left-radius: 24px;
                    border-bottom-right-radius: 24px;

                    &-body {
                        padding-top: 0;
                    }
                }

                &__post {
                    margin-top: 0;
                }

                &__name {
                    line-height: 165%;
                }

                &__body {
                    padding: 32px;
                    padding-bottom: 10px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &__avatar {
                    width: 57px;
                    height: 57px;
                }
            }



            &.slider-block {
                .slick-list {
                    margin-left: 0;
                    margin-right: -24px;
                }
            }
        }
    }
}

@media(max-width: 480px) { 
    .review {
        &__slider {
            .review {
                width: 269px;

                &__body {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 165%;
                    padding: 24px;
                    padding-bottom: 10px;
                }
            }
        }
    }
}