.service {
    &__title {
        font-weight: bold;
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 20px;
    }

    &__section {
        margin-top: 32px;

        &-btn {
            display: none;
        }

        &-top {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            .btn {
                margin-top: 0 !important;
                margin-left: 24px;
                padding: 11px 18px 11px;
            }
        }
    }

    &__body {
        .btn {
            margin-top: 12px;
        }
    }

    &__block {
        margin-bottom: 80px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        font-weight: 500;
    }


}


@media (max-width: 1440px) and (min-width: 1141px) {
    .service {
        &__title {
            font-size: 2.5vw;
            line-height: 1.3;
            margin-bottom: 1.2vw;
        }

        &__block {
            margin-bottom: 5.3vw;
        }

        &__body {
            .btn {
                margin-top: 0.5vw;
                font-size: 1vw;
                padding: 0.8vw 1.3vw;
            }

            p {
                font-size: 1.25vw;
            }
        }
    }
}

@media(max-width: 1140px) { 
    .service {
        &__title {
            font-size: 28px;
            line-height: 33px;
        }

        &__section {
            &-top {
                h4 {
                    font-size: 17px;
                    line-height: 132%;
                }

                .btn {
                    padding: 6px 13px;
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }

        &__block {
            margin-bottom: 72px;
        }

        &__body {
            p {
                font-size: 15px;
                line-height: 165%;
            }
        }
    }
}

@media(max-width: 960px) { 
    .service {
        &__section {
            &-top {
                .btn {
                    border-width: 1px;
                    margin-left: 19px;
                    padding: 7px 13px;
                }
            }
        }
    }
}

@media(max-width: 640px) { 
    .service {
        &__title {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 12px;
        }

        &__block {
            margin-bottom: 64px;
        }

        &__body {
            .btn {
                margin-top: 5px; 
            }
        }
    }
}

@media(max-width: 480px) {
    .service {
        &__block {
            margin-bottom: 54px;
        }

        &__section {
            margin-top: 24px;

            &-btn {
                display: block;
                margin-top: 10px;

                .btn {
                    border-width: 1px;
                    margin-top: 0;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    padding-left: 13px;
                    padding-right: 13px;
                }
            }

            &-top {
                margin-bottom: 12px;

                .btn {
                    display: none;
                }
            }
        }
    }
}