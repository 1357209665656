.control-block {
    margin-bottom: 48px;

    &__main {
        display: none;
    }

    &__control {
        display: none;
    }
}

@media(max-width: 960px) {
    .control-block {
        position: relative;
        flex-grow: 1;
        margin-bottom: 0;

        &.is-active {
            .control-block {
                &__main {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;

                    &::before {
                        opacity: 1;
                    }
                }

                &__control {
                    svg {
                        transform: rotate(180deg)
                    }
                }
            }

            .radio {
                &__list {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }

        &__container {
            width: 100%;
            margin-top: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__control {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;
            flex: none;

            svg {
                transition: transform 0.3s ease;
            }
        }

        &__name {
            font-weight: 500;
            font-size: 18px;
            line-height: 156%; 
            position: relative;
            z-index: 1;
        }

        &__main {
            display: flex;
            height: 48px;
            align-items: center;
            position: relative;
            overflow: hidden;
            padding-left: 16px;
            justify-content: space-between;
            background-color: #FFFFFF;
            box-shadow: 0px 5px 17px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            transition: border-radius 0.3s ease;

            &::before {
                content:" ";
                position: absolute;
                background: linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%);
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s ease;
            }
        }

        .radio {
            margin-bottom: 0;
            
            &__list {
                position: absolute;
                z-index: 5;
                background-color: #FFFFFF;
                box-shadow: 0px 13px 32px rgba(0, 0, 0, 0.03);
                border-radius: 0px 0px 12px 12px;
                padding: 12px 16px;
                right: 0;
                top: 100%;
                left: 0;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s ease;
            }

            &__label {
                font-weight: 500;
                font-size: 18px;
                line-height: 156%;
                padding: 10px 0;
            }

            &__control {
                display: none;
            }
        }
    }
}

