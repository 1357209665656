.hamburger {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    background-color: @green;
    margin-left: 17px;

    span {
        width: 24px;
        height: 2px;
        margin: 3px 0;
        display: block;
        background: #FFFFFF;
    }
}

@media(max-width: 1140px) {
    .hamburger {
        display: flex;
    }
}


@media(max-width: 960px) {
    .hamburger {
        width: 64px;
        height: 64px;
        margin-left: 12px;
    }
}

@media(max-width: 640px) {
    .hamburger {
        width: 56px;
        height: 56px;
        margin-left: 12px;

        span {
            width: 20px;
            margin: 0 0 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media(max-width: 480px) {
    .hamburger {
        width: 48px;
        height: 48px;
        margin-left: 10px;
    }
}