.social {
    display: flex;
    align-items: center;

    &__item {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: @lg2;
        margin-right: 8px;
        transition: background-color 0.3s ease;

        span {
            transition: color 0.3s ease;
        }

        .svg-icon {
            fill: @green;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &--big {
        .social {
            &__item {
                width: 56px;
                height: 56px;
                border-radius: 28px;
                background-color: @dark4;
                margin-right: 14px;

                &:last-child {
                    margin-right: 0;
                }


                .svg-icon {
                    width: 21px;
                    height: auto;

                    use {
                        fill: #262526;
                    }
                }

                &--text {
                    width: auto;
                    padding-left: 17px;
                    padding-right: 17px;

                    span {
                        font-size: 18px;
                        line-height: 156%;
                        margin-left: 10px;
                        color: #262526;
                    }
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .social {
        &__item {
            &:hover {
                background-color: #fff;

                span {
                        color: #262526;
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .social {
        &--big {
            .social {


                &__item {
                    width: 40px;
                    height: 40px;
                    margin-right: 6px;

                    .svg-icon {
                        width: 16px;
                    }

                    &--text {
                        width: auto;
                        padding-left: 12px;
                        padding-right: 12px;
                        
                        span {
                            font-size: 13px;
                            line-height: 150%;
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 1440px) and (min-width: 1141px) {
    .social {
        &__item {
            width: 2.2vw;
            height: 2.2vw;
            margin-right: 0.5vw;
        }

        &--big {
            .social {
                &__item {
                    width: 4vw;
                    height: 4vw; 
                    margin-right: 1vw;

                    .svg-icon {
                        width: 1.4vw;
                    }

                    &--text {
                        width: auto;

                        span {
                            font-size: 1.27vw;
                        }
                    }
                }
            }
        }
    }
}