.city-list {
    &__name {
        font-weight: bold;
        font-size: 20px;
        line-height: 132%;
        color: #BDBDBD;
        margin-bottom: 16px;
    }

    &__wrap {
        display: flex;
        margin: 0 -21px;
    }

    &__link {
        margin-top: 56px;
        display: inline-flex;
        align-items: flex-start;
        flex-direction: column;

        span {
            font-weight: 500;
            font-size: 18px;
            line-height: 156%; 
            color: @green;
            text-decoration: underline;
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
        padding: 0 21px;
    }
}