.banner-about {
    overflow: hidden;
    margin-top: -102px;
    text-align: center;
    color: #fff;
    background: linear-gradient(180deg, #24775A 0%, #3DA965 100%);

    .container {
        position: relative;
        padding-top: 88px;
        padding-bottom: 97px;
    }

    &__circle {

        &-big {
            bottom: -95px;
            left: 150px;
            position: absolute;
            border-radius: 50%;
            width: 420.26px;
            height: 420.26px;
            opacity: 0.4;
            border: 97.7585px solid @lg3;
            transition: transform 0.3s linear;
        }
       
        &-small {
            top: -63px;
            right: 362px;
            position: absolute;
            border-radius: 50%;
            width: 178.59px;
            height: 178.59px;
            opacity: 0.4;
            border: 49.5429px solid @lg3;
            transition: transform 0.3s linear;
        }
    }


    &__title {
        font-weight: 700;
        font-size: 68px;
        line-height: 80px;
        margin-bottom: 19px;
        position: relative;
    }

    &__text {
        max-width: 620px;
        position: relative;
        margin: 0 auto;
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .banner-about {
        &__title {
            font-size: 4.8vw;
            margin-bottom: 0.7vw;
        }

        &__text {
            font-size: 1.2vw;
            max-width: 44.5vw;
            line-height: 1.5;
        }
    }
}

@media (max-width: 1140px) {
    .banner-about {
        margin-top: -65px;

        .container {
            position: relative;
            padding-top: 61px;
            padding-bottom: 70px;
        }

        &__title {
            font-size: 52px;
            line-height: 61px;
            margin-bottom: 13px;
        }

        &__text {
            font-size: 17px;
            line-height: 150%;
        }
    }
}

@media(max-width: 960px) { 
    .banner-about {
        margin-top: -77px;
    }
}

@media (max-width: 640px) {
    .banner-about {
        margin-top: -93px;

        .container {
            padding-top: 83px;
            padding-bottom: 82px;
        }


        &__text {
            max-width: initial;
            font-size: 14px;
            line-height: 150%;
        }

        &__title {
            font-size: 36px;
            line-height: 42px;
            margin-bottom: 8px;
        }
    }
}

@media (max-width: 480px) {
    .banner-about {
        margin-top: -93px;

        .container {
            padding-top: 53px;
            padding-bottom: 49px;
        }
    }
}