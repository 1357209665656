.up {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: @green;
    position: fixed;
    bottom: 43px;
    right: 43px;
    cursor: pointer;
    z-index: 15;
    opacity: 0;
    pointer-events: none;
    transition: box-shadow 0.3s, opacity 0.3s ease;

    &.is-active {
        opacity: 1;
        pointer-events: auto;
    }

    &:hover {
        opacity: 0.7;
    }

}

@media(max-width: 960px) {
    .up {
        right: 0;
        bottom: 0;
        border-radius: 0;
    }
}