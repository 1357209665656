.slider-block {

    .slick-list {
        overflow: visible;
        margin: 0 -16px;
    }

    .slick-arrow {
        position: absolute;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        width: 32px;
        height: 32px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        z-index: 2;
        border: 0;
        transition: opacity 0.3s ease;

        &.slick-disabled {
            opacity: 0;
            pointer-events: none;
        }

        &.slick-next {
            right: -64px;
        }

        &.slick-prev {
            left: -64px;

            .svg-icon {
                transform: rotate(180deg);
            }
        }

        .svg-icon {
            stroke: @dark;
            fill: transparent;
            opacity: 0.3;
        }
    }
}

@media(max-width: 1140px) {
    .slider-block {
        .slick-arrow {
            &.slick-next {
                right: -11px;
            }
    
            &.slick-prev {
                left: -11px;
            }
        }
    }
}