.city {
    margin-left: 57px;
    transition: opacity 0.3s ease;

    &__main {
        display: inline-flex;
        align-items: center;
    }

    &__city {
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        color: @green;
        border-bottom: 2px dashed fade(#24775A, 30);
        transition: border-color 0.3s ease;
    }

    &__title {
        font-size: 16px;
        line-height: 156%;
        color: #BDBDBD;
        margin-right: 8px;
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .city {
        margin-left: 4vw;

        &__title {
            font-size: 1.1vw;
        }

        &__city {
            font-size: 1.2vw;
        }
    }
}

@media(min-width: 960px) {
    .city {
        &__city {
            &:hover {
                border-bottom-color: @green;
            }
        }
    }
}

@media(max-width: 1140px) {
    .city {
        margin-left: 38px;

        &__title {
            display: none;
        }

        &__city {
            font-size: 15px;
            line-height: 100%;
            border-bottom-width: 1.5px;
        }
    }
}

@media(max-width: 960px) {
    .city {
        margin-left: 23px;
    }
}

@media(max-width: 640px) {
    .city {
        margin-left: 32px;
    }
}

@media(max-width: 480px) {
    .city {
        margin-left: 16px;

        &__city {
            font-size: 12px;
        }
    }
}