.info {
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }

    &__name {
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
        margin-bottom: 12px;
    }

    &__text {
        font-size: 18px;
        line-height: 175%;
    }
}

@media(max-width: 640px) { 
    .info {
        &__name {
            font-size: 15px;
            line-height: 132%;
            margin-bottom: 4px;
        }

        &__text {
            font-size: 14.25px;
            line-height: 165%;
        }
    }
}

@media(max-width: 640px) { 

}