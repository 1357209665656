.menu-aside {
    margin-bottom: 37px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: @green;
        margin-bottom: 27px;
        transition: color 0.3s ease;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            .svg-icon {
                use {
                    fill: @green;
                }
            }
        }

        &.is-active {
            color: #262526;
            /*
            .menu-aside {
                &__control {
                    transform: rotate(90deg);
                }
            }*/
        }
    }

    &__control {
        .svg-icon {
            display: block;

            use {
                fill: @lg2;
            }
        }
    }
}

@media(max-width: 1140px) { 
    .menu-aside {
        margin-bottom: 33px;
        
        &__item {
            font-size: 14.25px;
            line-height: 165%;
            margin-bottom: 25px;
        }

        &__control {
            flex: none;
        }

        &--anchor {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0;

            .menu-aside {
                &__item {
                    font-size: 16px;
                    line-height: 19px;
                    padding-bottom: 3px;
                    border-bottom: 1px dashed fade(@green , 60);
                }

                &__control {
                    display: none;
                }
            }
        }
    }
}