.list-icon {
    &__item {
        display: flex;
        align-items: center;
        min-height: 56px;
        margin-bottom: 28px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    span {
        font-weight: bold;
        font-size: 22px;
        line-height: 132%;
        color: @green;
        letter-spacing: -0.02em;
        width: 64px;
        flex: none;
    }

    &__icon {
        display: flex;
        margin-right: 32px;
        flex: none;

        .svg-icon {
            fill: transparent;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
    }

    &--small {
        .list-icon {
            &__item {
                margin-bottom: 24px;
                min-height: inherit;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__text {
                font-weight: normal;
            }
        }
    }

    &--text {
        .list-icon {
            &__item {
                margin-bottom: 16px;
            }
        }
    }
}

@media(max-width: 1140px) {
    .list-icon {
        &__text {
            font-size: 17px;
            line-height: 156%;  
        }

        &__item {
            margin-bottom: 15px;
        }

        &__icon {
            margin-right: 24px;
        }
    }
}

@media(max-width: 960px) { 
    .list-icon {
        &__item {
            min-height: initial;
            margin-bottom: 12px;
        }
    }
}

@media(max-width: 480px) {
    .list-icon {
        &__item {
            display: block;
            text-align: center;
            margin-bottom: 22px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__text {
            font-size: 14.25px;
            line-height: 150%;
        }

        &__icon {
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 8px;

            .svg-icon {
                display: block;
                width: 30px;
                height: auto;
            }
        }
    }
}