.tag-type {
    display: flex;
    flex-wrap: wrap;

    &__item {
        margin-right: 8px;
        font-size: 15px;
        line-height: 160%;
        color: @dark2;
        margin-bottom: 8px;
        padding: 0 9px;
        background-color: #fff;
        border-radius: 12px;
    }
}

@media(max-width: 1140px) {
    .tag-type {
        &__item {
            font-size: 9.50507px;
            line-height: 165%;
            padding-left: 7px;
            padding-right: 7px;
        }
    }
}