.card-aside {


    &__aside {
        margin-bottom: 56px;
    }

    &__item {
        display: flex;
        align-items: center;
        color: inherit;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 132%;
        color: #BDBDBD;
        margin-bottom: 32px;
    }

    &__name {
        font-weight: bold;
        font-size: 20px;
        line-height: 132%;
        transition: color 0.3s ease;
    }

    &__type {
        font-size: 15px;
        line-height: 160%;
        color: @lightbrown;
        transition: color 0.3s ease;
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 77px;
        height: 77px;
        background-color: #fff;
        border-radius: 8px;
        margin-right: 16px;
        flex: none;

        img {
            max-width: 90%;
            max-height: 90%;
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .card-aside {
        
        &__aside {
            margin-bottom: 3.5vw;
        }

        &__title {
            font-size: 1.2vw;
            margin-bottom: 2vw;
        }

        &__item {
            margin-bottom: 0.8vw;
        }

        &__type {
            font-size: 1vw;
            line-height: 1.2;
        }

        &__img {
            width: 5vw;
            height: 5vw;
            margin-right: 1vw;
        }

        &__name {
            font-size: 1.3vw;
            line-height: 1.2;
        }
    }
}

@media(min-width: 960px) {
    .card-aside {
        &__item {
            &:hover {
                .card-aside {
                    &__name {
                        color: @green;
                    }

                    &__type {
                        color: @green;
                    }
                }
            }
        }
        
    }
}

@media(max-width: 960px) {
    .card-aside {
        &__aside {
            flex-direction: column;
            margin-bottom: 48px;
        }

        &__title {
            font-weight: bold;
            font-size: 20px;
            line-height: 23px;
            color: #333234;
        }

        &__item {
            width: 50%;
            padding: 0 11px;
            margin-bottom: 24px;
        }

        &__img {
            width: 60px;
            height: 60px;
            margin-right: 6px;
        }

        &__type {
            font-size: 18px;
            line-height: 156%;
        }

        &__name {
            font-size: 17px;
            line-height: 132%;
        }

        &__body {
            &-wrap {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -11px -24px;
            }
         
        }
    }
}