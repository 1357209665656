.menu {
    height: 100%;
    margin-right: 32px;

    &>ul {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        height: 100%;

        &>li {
            display: flex;
            align-items: center;
            list-style: none;
            margin-right: 32px;
            height: 100%;

            &:last-child {
                margin-right: 0;
            }

            &>a {
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                color: inherit;
                
        
                &.is-active {
                    color: @dark2;
                }
        
                
            }
        }
    }

    &__drop {
        opacity: 0;
        pointer-events: none;
        width: 100%;
        padding-top: 57px;
        padding-bottom: 56px;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        transition: opacity 0.3s ease;

        .container {
            display: block; 
            height: auto !important;
        }

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
            height: 83px;
            pointer-events: none;
        }
    }
}

@media(min-width: 960px) {
    .menu {
        &>ul {
            &>li {
                &:hover {
                    &>a {
                        color: @green;
                    }

                    .menu {
                        &__drop {
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .menu {
        &>ul {
            &>li {
                margin-right: 1.7vw;

                &>a {
                    font-size: 1.3vw;
                }
            }
        }

        &__drop {
            padding-top: 5.8vw;
        }
    }
}

