.big-form {
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: 101px;
    background-color: @green;

    &__title {
        font-weight: bold;
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 4px;
        color: #fff;
        text-align: center;
    }

    &__agree {
        font-size: 15px;
        line-height: 160%;
        color: #fff;
        opacity: 0.3;
        margin-top: 12px;
        text-align: center;
    }

    &__btn {
        margin-top: 32px;
    }

    &__field {
        width: 100%;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        textarea {
            width: 100%;
            background: #278162;
            box-shadow: 0px 13px 32px rgba(0, 0, 0, 0.03);
            border-radius: 8px;
            border: 0;
            color: #FFFFFF;
            font-weight: normal;
            height: 56px;
            padding: 18px 24px;
            height: 112px;

            &::-webkit-input-placeholder { color: #FFFFFF; opacity: 0.65; font-weight: normal;}
            &::-moz-placeholder         { color: #FFFFFF; opacity: 0.65; font-weight: normal;}
            &:-moz-placeholder          { color: #FFFFFF; opacity: 0.65; font-weight: normal;}
            &:-ms-input-placeholder     { color: #FFFFFF; opacity: 0.65; font-weight: normal;}
    
        }

        input[type='text'] {
            width: 100%;
            background: #278162;
            box-shadow: 0px 13px 32px rgba(0, 0, 0, 0.03);
            border-radius: 8px;
            border: 0;
            color: #FFFFFF;
            font-weight: normal;
            height: 56px;
            padding: 0 24px;

            &::-webkit-input-placeholder { color: #FFFFFF; opacity: 0.65; font-weight: normal;}
            &::-moz-placeholder         { color: #FFFFFF; opacity: 0.65; font-weight: normal;}
            &:-moz-placeholder          { color: #FFFFFF; opacity: 0.65; font-weight: normal;}
            &:-ms-input-placeholder     { color: #FFFFFF; opacity: 0.65; font-weight: normal;}
        }
    }
    
    &__subtitle {
        font-size: 15px;
        line-height: 160%;
        color: #fff;
        text-align: center;
        margin-bottom: 48px;
    }

    &__container {
        width: 620px;
        margin: 0 auto;
    }
}

@media(max-width: 1140px) {
    .big-form {
        padding-top: 67px;
        margin-top: 72px;
        padding-bottom: 56px;

        &__title {
            font-size: 28px;
            line-height: 33px;
        }

        &__agree {
            font-size: 11.8792px;
            line-height: 160%;
        }

        &__subtitle {
            font-size: 12px;
            line-height: 165%;
            margin-bottom: 33px;
        }

        &__field {
            input[type=text] {
                height: 48px;
                font-size: 16px;
                line-height: 132%;
                padding: 0 20px;
            }

            textarea {
                font-size: 16px;
                line-height: 132%;
                height: 96px;
            }
        }

        &__text {
            font-size: 12px;
            line-height: 165%;
        }

        &__container {
            width: 430px;
        }
    }
}

@media(max-width: 960px) {
    .big-form {
        padding-bottom: 73px;

        &__container {
            width: 100%;
        }

        &__field {
            margin-bottom: 8px;
        }
    }
}

@media(max-width: 480px) {
    .big-form {
        padding-top: 42px;
        padding-bottom: 44px;

        &__agree {
            margin-top: 6px;
        }

        &__subtitle {
            margin-bottom: 25px;
        }
    }
}