.principle {
    display: flex;
    margin: 102px -32px 0;

    &__item {
        margin: 0 32px;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #81324A;
        position: relative;
        margin: 0 auto 48px;

        &::before {
            content:"";
            width: 172px;
            height: 172px;
            left: -26px;
            top: -26px;
            position: absolute;
            border-radius: 50%;
            background: rgba(129, 50, 74, 0.08);
        }
    }

    &__name {
        font-weight: bold;
        font-size: 20px;
        line-height: 132%;
        text-align: center;
        margin-bottom: 6px;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    &__text {
        font-size: 15px;
        line-height: 160%;
        text-align: center;
        color: #BDBDBD;
    }
}

@media(max-width: 960px) { 
    .principle {
        flex-wrap: wrap;
        margin-top: 65px ;
        margin-left: -13px;
        margin-right: -13px;
        margin-bottom: -52px;

        &__name {
            font-size: 15px;
            line-height: 132%;
            max-width: 100%;

            br {
                display: none;
            }
        }

        &__text {
            font-size: 12px;
            line-height: 165%;
        }

        &__icon {
            width: 70px;
            height: 70px;
            margin: 0 auto 25px;

            &::before {
                left: -15px;
                top: -15px;
                width: 100px;
                height: 100px;
            }
            
            img {
                max-width: 65%;
            }
        }

        &__item {
         
            width: 50%;
            margin: 0;
            padding: 0 13px;
            margin-bottom: 52px;
        }
    }
}

@media (max-width: 480px) {
    .principle {
        &__item {
            width: 100%;
        }
    }
}