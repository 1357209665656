.filter {
    &__top {
        display: none;
    }

    &__section {
        margin-bottom: 48px;

        .banner-aside {
            margin-top: -35px;
        }
    }

    .btn-reset {
        width: 100%;
    }

    &__item {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-size: 15px;
        line-height: 160%;
        color: @dark4;
        margin-bottom: 8px;
    }
}

.filter-btn {
    display: none;
}

@media(max-width: 1140px) { 
    .filter {
        &__section {
            margin-bottom: 30px;
        }

        &__title {
            font-size: 14.25px;
            line-height: 165%;
        }
    }
}


@media(max-width: 960px) { 
    .filter {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 7;
        display: flex;
        justify-content: flex-end;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;

        &.is-active {
            opacity: 1;
            pointer-events: auto;

            .filter {
                &__wrap {
                    transform: translateX(0);
                }
            }
        }

        .select {
            .jq-selectbox__select {
                height: 40px;
                padding-top: 7px;
                padding-bottom: 8px;
                overflow: hidden;

                
            }

            .jqselect {
                &.dropdown  {
                    

                    .jq-selectbox__select {
                        border-radius: 8px 8px 0px 0px;
                    }
                }
            }


            .jq-selectbox__dropdown {
                position: relative;
                top: initial !important;
                width: 100%;
                background-color: #fff;
                border-radius: 0px 0px 8px 8px;

                ul {
                    margin: 0;
                    padding: 12px 18px;

                    li {
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 165%;
                        margin-bottom: 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .jq-selectbox__trigger {
                width: 40px;
                height: 40px;
            }

            .jq-selectbox__select-text {
                font-size: 15px;
                line-height: 165%;
                position: relative;
            }
        }

        &__title {
            font-size: 12px;
            line-height: 165%;
            margin-bottom: 0;
        }

        &__section {
            &:last-child {
                margin-bottom: 0;
            }

            .doc {
                display: none;
            }

            .btn {
                width: 100%;
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            
        }

        &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 48px;
            font-weight: bold;
            font-size: 17px;
            line-height: 132%;
            color: #BDBDBD;
            margin-bottom: 32px;
        }

        &__close {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                display: block;
            }
        }

        &__container {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            padding: 0 16px;
        }

        &__wrap {
            width: 300px;
            background: #f4f3f4;
            
            position: relative;
            z-index: 5;
            transition: transform 0.3s ease;
            transform: translateX(100%);

            &::before {
                content:" ";
                width: 56px;
                left: -56px;
                top: 0;
                bottom: 0;
                position: absolute;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%);
                opacity: 0.4;
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }
        }

        
    }

    .filter-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 24px;
        flex: none;
        width: 48px;
        height: 48px;
        border-radius: 12px;
        background-color: @crimson;

        .svg-icon {
            use {
                fill: #fff;
            }
        }
    }
}

@media(max-width: 480px) {
    .filter-btn {
        margin-left: 10px;
    }
}
