.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    font-size: 16px;
    line-height: 156%;
    padding: 3.5px 16px;
    border-radius: 16px;
    border: 2px solid transparent;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s ease;

    &--right {
        .svg-icon {
            margin-left: 4px;
        }
    }

    &--big {
        font-weight: bold;
        font-size: 22px;
        line-height: 156%;
        letter-spacing: 0.02em;
        border-radius: 12px;
        padding: 13px 20px;

    }

    &--small {
        padding: 13px 18px 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        border-radius: 8px; 
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }

    &--mini {
        padding: 15px 24px;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        border-radius: 8px;
    }

    &--default {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        padding: 18px 31px;
        border-radius: 8px;
    }

    &--brown {
        background-color: @crimson;
        color: #fff;
    }

    &--grey {
        background-color: @lightgrey;
        color: @dark4;
    }

    &--green {
        background-color: @lg3;
        color: #fff;
    }

    &--green-dark {
        background-color: @green;
        color: #FFFFFF;
    }

    &--green-light2 {
        background-color: @lg3;
        color: #fff;

        &:disabled {
            pointer-events: none;
            background: #BDBDBD;
        }

        .svg-icon {
            use {
                fill: #fff;
            }
        }
    }

    &--out-green {
        color: @green;
        border-color:  rgba(36, 119, 90, 0.5);
    }

    &--green-light {
        background-color: @lg2;
        color: @green;
    }

    &--white {
        background-color: #fff;
        color: @green;
    }

}


@media(min-width: 960px) {
    .btn {
        &--green-dark {
            &:hover {
                background-color: transparent;
                border-color: @green;
                color: @green;
            }
        }

        &--green-light2 {
            &:hover {
                background-color:  @green;
                border-color: @green;
                color: #fff;
            }
        }

        &--green-light {
            &:hover {
                background-color: #FFFFFF;
                color: @green;
            }
           
        }

    }
}

@media(max-width: 1140px) {
    .btn {
        &--big {
            font-size: 18px;
            line-height: 156%;
            padding: 8px 20px;
        }

        &--default {
            font-size: 16px;
            line-height: 19px;
            padding: 18px 28px;
        }

        &--mini {
            font-size: 12px;
            line-height: 14px; 
            padding: 9px 18px;
            border-radius: 6px;
        }

        &--14 {
            font-weight: bold;
            font-size: 14px;
            line-height: 16px; 
            padding: 15px 18px;
            border-radius: 6px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .btn {
        font-size: 1.1vw;
        padding: 0.9vw 1.1vw;

        &--mini {
            font-size: 0.8vw;
            padding: 0.6vw 1.3vw;
        }
    }
}

.btn {
    &__aside {
        margin-top: 56px;

        .btn {
            width: 100%;
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .btn {
        &__aside {
            margin-top: 2vw;
        }
    }
}


@media(max-width: 480px) {
    .btn {
        &--big {
            font-size: 16px;
            line-height: 156%;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}


.btn-reset {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border: 2px solid rgba(36, 119, 90, 0.3);
    border-radius: 8px;
    color: @green;

    .svg-icon {
        display: block;
        margin-right: 10px;
        margin-top: -1px;

        use {
            stroke: @green;
        }
    }
}

@media(max-width: 1140px) { 
    .btn-reset {
        padding-top: 11px;
        padding-bottom: 11px;
        font-weight: 500;
        font-size: 14.25px;
        line-height: 165%;
        text-transform: initial;
        letter-spacing: initial;
    }
}

@media(max-width: 960px) {
    .btn-reset {
        padding-top: 14px;
        padding-bottom: 14px;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.05em;
        font-weight: bold;
        text-transform: uppercase;
    }
    
}

.btn-submit {
    width: 100%;
    border: 0;
    padding: 19px 19px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    color: #FFFFFF;
    letter-spacing: 0.05em;
    text-transform: uppercase;  
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    background-color: @lg3;
    border-radius: 8px;
}

@media(max-width: 1140px) {
    .btn-submit {
        font-size: 14px;
        line-height: 16px;
        padding: 16px 27px 16px;
    }
}

.btn-hh {
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    background: #9C070D;
    flex: none;

    &__text {
        font-weight: bold;
        font-size: 13px;
        line-height: 143%;  
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 10px 14px  8px 17px;
    }

    &__logo {
        flex: none;
        border-radius: 8px;
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #D20A11;

    }
}


@media(max-width: 1140px) {
    .btn-hh {
        &__text {
            font-size: 11px;
            line-height: 130%;
            padding: 5px 9px 4px 11px;
        }

        &__logo {
            width: 44px;
            height: 44px;
        }
    }
}

@media(max-width: 960px) {
    .btn-hh {
        &__text {
            font-size: 12px;
            line-height: 130%;
            padding:  13px 12px 11px;

            br {
                display: none;
            }
        }

        &__logo {
            width: 40px;
            height: 40px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}