.client {
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 144px;
        background: #FFFFFF;
        box-shadow: 0px 11px 40px rgba(0, 0, 0, 0.05);
        border-radius: 24px;

        img {
            opacity: 0.7;
            max-height: 80%;
        }
    }

    &__slider {

        &.slider-block {
            .slick-list {
                margin: 0 -24px;
            }
        }
        

        .client {
            &__item {
                width: 100%;
                opacity: 0.2;
                pointer-events: none;
                transition: opacity 0.3s ease;
            }
        }

        .slick-slide {
            padding: 0 24px;

            &.slick-active {
                .client__item {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }

    &__plit {

        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: -63px;

        &-wrap {
            display: flex;
            flex-wrap: wrap;
        }
       

        .client {
            &__item {
                width: calc(~"100%/6 - 48px");
                margin: 0 24px 63px;
            }
        }
    }
}

@media(max-width: 1140px) {
    .client {
        &__slider {
            &.slider-block .slick-list {
                margin: 0 -16px;
            }

            .slick-slide {
                padding: 0 16px;
            }

            .client {
                &__item {
                    opacity: 1;
                    width: 142px;
                    height: 113.6px;   
                    box-shadow: 0px 8.67778px 31.5556px rgba(0, 0, 0, 0.05);
                    border-radius: 18.9333px;

                    img {
                        opacity: 1;
                    }
                }
                
            }
        }
    }
}

@media(max-width: 960px) { 
    .client {
        &__plit {
          
            margin-bottom: 0;
            overflow-x: auto;

            &-wrap {
                width: 1168px;
            }
 
            .client {
                &__item {
                    width: 142px;
                    height: 113.6px;
                    margin: 0 16px 63px;
                }
            }
        }
    }
}