.news {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -24px -48px;

    &__img {
        height: 208px;
        background-position: center;
        background-size: cover;
        border-radius: 16px;
        margin-bottom: 16px;
    }

    &__name {
        font-weight: bold;
        font-size: 18px;
        line-height: 136%;
        margin-bottom: 8px;
    }

    &__order {
        width: calc(~"33% - 40px");
        margin: 0 20px 48px;
        background-color: @green;
        padding: 32px;
        border-radius: 16px;

        &-title {
            font-weight: bold;
            font-size: 22px;
            line-height: 132%;
            color: #fff;
            margin-bottom: 8px;
        }

        &-btn {
            .btn {
                width: 100%;
            }
        }

        &-text {
            font-size: 15px;
            line-height: 160%;
            color: #fff;
            margin-bottom: 24px;
        }

        &--row {
            width: 100%;
            margin: 32px 0 0;
            display: flex;
            align-items: center;

            .news {
                &__order {
                    &-text {
                        margin-bottom: 0;
                    }

                    &-btn {
                        .btn {
                            margin-top: 0 !important;
                        }
                    }
                }
            }

        }
    }

    &__date {
        font-size: 15px;
        line-height: 160%;
        color: @dark4;
    }

    &__item {
        display: flex;
        flex-direction: column;
        width: calc(~"25% - 48px");
        color: inherit;
        margin: 0 24px 48px;
    }

    &--webinars {

        align-items: flex-start;
        margin-left: -20px;
        margin-right: -20px;

        .news {
            &__item {
                width: calc(~"33% - 40px");
                margin: 0 20px 48px;
            }
        }
    }

    &__slider {
        .slick-list {
            margin: 0 -12px;
        }
        
        .slick-slide {
            padding: 0 12px;
        }

        .news {
            &__item {
                margin: 0;
                width: 100%;
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .news {
        margin: 0 -1.7vw -3vw;

        &__img {
            height: 14.5vw;
        }

        &__date {
            font-size: 1vw;
        }

        &__name {
            font-size: 1.2vw;
        }

        &__item {
            width: calc(~"25% - 3.4vw");
            margin: 0 1.7vw 3vw;
        }

        &__order {
            padding: 2.3vw 2.4vw;
            width: calc(~"33.3% - 2.4vw");
            margin: 0 1.2vw 3vw;

            &-text {
                font-size: 1.065vw;
                margin-bottom: 2vw;
            }

            &-title {
                font-size: 1.5vw;
            }

           
        }

        &--webinars {
            margin-left: -1.2vw;
            margin-right: -1.2vw;

            .news {
                &__item {
                    width: calc(~"33.3% - 2.4vw");
                    margin: 0 1.2vw 3vw;
                }
            }
        }
    }
}

@media(max-width: 1140px) { 
    .news {
        margin: 0 -12px -32px;

        &__item {
            width: calc(~"25% - 24px");
            margin: 0 12px 32px;
        }

        &__date {
            font-size: 14.25px;
            line-height: 165%;
        }

        &__name {
            font-size: 15px;
            line-height: 132%;
            margin-bottom: 11px;
        }

        &__img {
            height: 15vw;
            margin-bottom: 24px;
            border-radius: 8px;
        }

        &__order {
            width: calc(~"33.3% - 24px");
            margin: 0 12px 32px; 

            &--row {
                margin-top: 32px;
                width: 100%;
                margin-left: 0;
                margin-right: 0;

                .news {
                    &__order {
                        &-title {
                            font-size: 17px;
                            line-height: 132%;
                            margin-bottom: 9px;
                        }

                        &-body {
                            padding-right: 30px;
                        }

                        &-btn {
                            .btn {
                                padding-top: 10px;
                                padding-bottom: 10px;
                                font-size: 12px;
                                line-height: 14px;
                            }
                        }

                        &-text {
                            font-size: 12px;
                            line-height: 165%;
                        }
                    }
                }
            }

            &-title {
                font-size: 20px;
                line-height: 23px;
                margin-bottom: 6px;
            }

            &-btn {
                .btn {
                    font-size: 14px;
                    line-height: 16px;
                    padding-top: 13px;
                    padding-bottom: 13px;
                }
            }

            &-text {
                font-size: 14.25px;
                line-height: 165%;
                margin-bottom: 18px;
            }
        }

        &--webinars {
            margin-bottom: -32px;

            .news {
                &__item {
                    width: calc(~"33.3% - 24px");
                    margin: 0 12px 32px;
                }

                &__name {
                    margin-bottom: 6px;
                }

                &__img {
                    border-radius: 16px;
                    height: 21.8vw;
                    margin-bottom: 13px;
                }
            }
        }

        &__slider {
            .news {
                &__img {
                    height: 17vw;
                }
            }
        }
    }
}

@media(max-width: 960px) { 
    .news {
        &__item {
            width: calc(~"50% - 24px");
            margin: 0 12px 32px;
        }

        &__img {
            height: 28vw;
            margin-bottom: 6px;
        }

        &__name {
            font-size: 14.25px;
            line-height: 165%;
            margin-bottom: 2px;
        }

        &__order {
            width: calc(~"50% - 20px");
            margin: 0 10px 24px;

            &--row {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                margin-top: 32px;
            }
        }

        &--webinars {
            margin-bottom: -24px;
            margin-left: -10px;
            margin-right: -10px;

            .news {
                &__item {
                    width: calc(~"50% - 20px");
                    margin: 0 10px 24px;
                }

                &__name {
                    line-height: 161%;
                    margin-bottom: 3px;
                }

                &__img {
                    height: 32vw;
                    margin-bottom: 13px;
                }
            }
        }

        &__slider {
            .news {
                &__img {
                    height: 25.5vw;
                    margin-bottom: 22px;
                }

                &__name {
                    font-size: 15px;
                    line-height: 132%;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@media(max-width: 640px) { 
    .news {
        &__item {
            width: calc(~"100% - 24px");
            margin: 0 12px 32px;
        }

        &__img {
            height: 61.5vw;
            margin-bottom: 22px;
        }

        &__date {
            font-size: 15px;
            line-height: 160%;
        }

        &__name {
            font-size: 18px;
            line-height: 136%;
            margin-bottom: 8px;
        }

        &__order {
            width: calc(~"100% - 20px");

            &-btn {
                .btn {
                    padding: 13px 20px 12px;
                    font-size: 12px;
                    line-height: 14px;
                }
            }

            &--row {
                display: block;
                width: 100%;

                .news__order {
                    &-body {
                        padding-right: 0;
                    }

                    &-text {
                        font-size: 15px;
                        line-height: 165%;
                        margin-bottom: 24px;
                    }

                    &-title {
                        font-size: 20px;
                        line-height: 23px;
                    }
                }
            }
        }

        &__slider {
            .news {
                &__img {
                    height: 29.5vw;
                }
            }
        }

        &--webinars {
            margin-bottom: -16px;

            .news {
                &__item {
                    width: calc(~"100% - 20px");
                    margin-bottom: 16px;
                }

                &__date {
                    font-size: 14.25px;
                    line-height: 165%;
                }

                &__name {
                    font-size: 15px;
                    line-height: 132%;
                    margin-bottom: 6px;
                }

                &__img {
                    height: 43vw;
                    margin-bottom: 13px;
                }
            }
        }
    }
}

@media(max-width: 480px) { 
    .news {
        &__order {
            padding: 24px;

            &--row {
                .news__order {
                    &-text {
                        margin-bottom: 20px; 
                    }
                }
            }
        }

        &--webinars {
            .news {
                &__img {
                    height: 58vw;
                }
            }
        }

        &__slider {
            .news {
                &__img {
                    height: 50.5vw;
                    margin-bottom: 9px;
                }

                &__name {
                    margin-bottom: 5px;
                }
            }
        }
    }
}