.material {
    display: block;
    color: inherit;
    opacity: 0.2;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &__img {
        border-radius: 16px;
        height: 208px;
        margin-bottom: 22px;
        background-position: center;
        background-size: cover;
    }

    &__name {
        font-weight: bold;
        font-size: 18px;
        line-height: 136%;
        margin-bottom: 19px;
    }

    &__tag {
        display: flex;
        flex-wrap: wrap;
    }

    &__slider {
        .slick-slide {
            padding: 0 24px;

            &.slick-active {
                .material {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .material {
        &__name {
            font-size: 11.2873px;
            line-height: 165%;
            margin-bottom: 7px;
        }

        &__slider  {
            .slick-slide {
                padding: 0 16px;
            }

            .material {
                width: 182px;

                &__img {
                    height: 130px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}