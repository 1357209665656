.file {
    &__main {
        position: relative;
        
        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }

    &__btn {
        padding: 13px 14px 12px;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: @green;
        background-color: #FFFFFF;
        border-radius: 8px;

        .svg-icon {
            margin-right: 10px;
            use {
                fill: @green;
            }
        }
    }
}