.aside-info {
    padding: 32px 32px 27px;
    border-radius: 20px;
    background-color: @green;
    color: #fff;

    &__title {
        font-weight: bold;
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 32px;
        
        svg {
            display: none;
        }
    }

    &__item {
        margin-bottom: 16px;

        &-title {
            font-weight: bold;
            font-size: 20px;
            line-height: 132%;
            color: #fff;
        }

        &-text {
            font-size: 15px;
            line-height: 160%;
            color: @lg2;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .aside-info {
        padding:  2vw;

        &__title {
            font-size: 2.5vw;
            line-height: 1.2;
            margin-bottom: 2.2vw;
        }

        &__item {
            margin-bottom: 1vw;

            &-title {
                font-size: 1.4vw;
            }

            &-text {
                font-size: 1vw;
            }
        }
    }
}

@media(max-width: 1140px) { 
    .aside-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 69px 118px 85px;
        margin: 56px -40px -50px;
        border-radius: 0;
     
        a.aside-info__item-title {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 20px;
            line-height: 132%;
            width: 100%;
            background-color: @lg3;
            border-radius: 13px;
            padding: 12px;
        }

        &__body {
            width: 346px;
            flex: none;
        }

        &__title {
            font-size: 44px;
            line-height: 52px;
            font-weight: bold;
            margin-bottom: 0;
        }
    }  
}

@media(max-width: 960px) { 
    .aside-info {
        display: block;
        padding: 40px 40px 57px;
        margin-left: -38px;
        margin-right: -38px;

        &__body {
            width: 100%;
        }

        &__title {
            font-size: 36px;
            line-height: 42px;
            margin-bottom: 24px;
        }

    }
}


@media(max-width: 640px) {
    .aside-info {
        margin: 35px -32px -50px;
        padding: 32px 34px 50px;

        &__title {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 16px;
        }

        a.aside-info__item-title {
            font-size: 16px;
            line-height: 132%;  
            padding: 15px;
        }

        &__item {
            &-title {
                font-size: 15px;
                line-height: 132%;
            }

            &-text {
                font-size: 12px;
                line-height: 165%;
            }
        }
    }
}

@media(max-width: 480px) {
    .aside-info {
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
}