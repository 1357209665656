.contact-info {
    &__row {
        display: flex;
        margin: 0 auto;
        max-width: 93%;
    }

    &__title {
        font-weight: bold;
        font-size: 22px;
        line-height: 132%;
        margin-bottom: 40px;
    }

    &__col {
        width: 50%;
        padding: 0 32px;
    }

    &__item {
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }
        
        &-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 156%;
            margin-bottom: 12px;
        }

        &-body {
            font-size: 18px;
            line-height: 175%;

            a {
                color: #24775A;
                text-decoration: underline;
            }
        }
    }

    &__select {
        margin-bottom: 34px;

        &.select {
            .jq-selectbox__select {
                height: 64px;
                display: flex;
                align-items: center;
            }

            .jq-selectbox__trigger {
                width: 64px;
                height: 64px;
            }
        }
    }
}

@media(max-width: 1140px) {
    .contact-info {
        &__row {
            max-width: initial;
            margin: 0 -12px;
        }

        &__item {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }

            &-title {
                font-size: 15px;
                line-height: 165%;
                margin-bottom: 10px;
            }

            &-body {
                font-size: 15px;
                line-height: 165%;
            }
        }

        &__select {
            margin-bottom: 24px;
            &.select {
                .jq-selectbox__select {
                    height: 56px;
                }

                .jq-selectbox__trigger {
                    width: 56px;
                    height: 56px;
                }
            }
        }

        &__title {
            font-size: 17px;
            line-height: 132%;
            margin-bottom: 32px;
        }

        &__col {
            padding: 0 12px;
        }
    }
}

@media(max-width: 960px) {
    .contact-info {
        &__row {
            display: block;
            margin: 0;
        }

        &__item {
            margin-bottom: 21px;
        }

        &__col {
            width: 100%;
            padding: 0;

            &:first-child {
                margin-bottom: 72px;
            }
        }
    }
}

@media(max-width: 640px) {
    .contact-info {
        &__title {
            margin-bottom: 26px;
        }

        &__col {
            &:first-child {
                margin-bottom: 57px;
            }
        }

        &__select {
            margin-bottom: 20px;

            &.select {
                .jq-selectbox__select {
                    padding-left: 23px;
                }

                .jq-selectbox__select-text {
                    font-size: 17px;
                    line-height: 165%;
                }
            }
        }
    }
}