.select {

    .jq-selectbox {
        width: 100%;
    }

    .jq-selectbox__select {
        width: 100%;

        &.opened {
            .jq-selectbox__trigger {
                transform: rotate(180deg);
            }
        }
    }

    .jq-selectbox__select-text {
        position: relative;
    }

    .jqselect {
        &.dropdown  {
            .jq-selectbox__select {
                border-radius: 8px 8px 0px 0px;

                &::before {
                    opacity: 1;
                }
            }

            .jq-selectbox__trigger {
                transform: rotate(180deg);
            }
        }
    }

    .jq-selectbox__select {
        background-color: #fff;
        padding: 10px 48px 9px 17px;
        border-radius: 8px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            transition: opacity 0.3s ease;
            background: linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%);
        }
    }

    .jq-selectbox__dropdown {
        width: 100%;
        padding: 12px 16px;
        box-shadow: 0px 13px 32px rgba(0, 0, 0, 0.03);
        border-radius: 0px 0px 12px 12px;
        background-color: #fff;

        ul {
            padding: 0;
            margin: 0;

            li {
                font-size: 18px;
                line-height: 156%;
                padding: 10px 0;
                cursor: pointer;
                transition: color 0.3s ease;

                &.sel {
                    color: @lg3;
                }
            }
        }
    }

    .jq-selectbox__trigger {
        position: absolute;
        z-index: 3;
        right: 0;
        top: 0;
        height: 47px;
        width: 47px;
        background-image: url(../images/common/arrow-dark.png);
        background-position: center;
        background-repeat: no-repeat;
        transition: transform 0.3s ease;
    }

    .jq-selectbox__trigger-arrow {
        display: none;
    }

    &--green {

        .jq-selectbox__select {
            padding: 12px 15px 12px 24px;
            background-color: transparent;
            border: 2px solid rgba(36, 119, 90, 0.5);
        }

        .jq-selectbox__trigger {
            background-image: url(../images/common/arrow-green.png);
        }

        .jq-selectbox__select-text {
            color: @green;
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .select {
        .jq-selectbox__select-text {
            font-size: 1.25vw;
        }

        .jq-selectbox__select {
            padding-top: 0.6vw;
            padding-bottom: 0.6vw;
            padding-left: 1.6vw;
        }

        .jq-selectbox__trigger {
            width: 4vw;
            height: 4vw;
            background-size: 1vw;
        }
    }
}


@media(max-width: 960px) { 
    .select {
        .jq-selectbox__select-text {
            font-weight: 500;
            font-size: 15px;
            line-height: 165%;
        }

        .jq-selectbox__trigger {
            width: 42px;
            height: 42px;
        }

        &--green {
            .jq-selectbox__trigger {
                width: 49px;
                height: 49px;
            }
        }
    }
}