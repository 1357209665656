.radio {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 2;

        &:checked {
            &~.radio {
                &__wrap {
                    box-shadow: 0px 6px 16px rgba(69, 185, 105, 0.23);

                    .radio {
                        &__control {
                            &::before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
       
    }

    &__wrap {
        display: inline-flex;
        padding: 12px 14px;
        align-items: center; 
        background-color: #FFFFFF;
        border-radius: 12px;
        transition: box-shadow 0.3s ease
    }

    &__label {
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 12px;

        .radio {
            margin-right: 12px;
        }
    }

    &__control {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        position: relative;
        margin-right: 8px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid @dark2;

        &::before {
            content: " ";
            background-color: @lg3;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.3s ease
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &--default {
        margin-bottom: 20px;
       

        &:last-child {
            margin-bottom: 0;
        }

        input {
            &:checked {
                &~.radio {
                    &__wrap {
                        box-shadow: none;
                    }
                }
            }
        }

        .radio {
            &__wrap {
                align-items: flex-start;
                padding: 0;
                background-color: transparent;
            }

            &__control {
                margin-right: 17px;
                margin-top: 2px;
            }
        }
    }

    &--city {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
        
        input {
            &:checked {
                &~.radio {
                    &__wrap {
                        background-color: transparent;
                        box-shadow: none;
                    }
                }
            }
        }
        .radio {
            &__control {
                margin-right: 12px;
                width: 12px;
                height: 12px;
                background-color: #F4F3F4;
                border: 0;
            }

            &__wrap {
                padding: 0;
                background-color: transparent;
            }
        }
    }
}


@media(max-width: 1140px) { 
    .radio {
        &__label {
            font-size: 14.25px;
            line-height: 165%;
        }

        &__wrap {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &__row {
            margin-bottom: 13px;

            .radio {
                margin-bottom: 13px;
            }
        }

        &--default {
            margin-bottom: 14px;

            .radio {
                &__control {
                   margin-top: 0;
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .radio {
        &__wrap {
            padding-left: 6px;
            padding-right: 10px;
        }

        &__row {
            .radio {
                margin-right: 8px;
                margin-bottom: 8px;
            }
        }
    }
}