.news-aside {
    &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 132%; 
        color: #BDBDBD;
        margin-bottom: 24px;
    }

    &__item {
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__name {
        font-weight: bold;
        font-size: 20px;
        line-height: 132%; 
        color: #262526;
        transition: color 0.3s ease;
    }

    &__date {
        margin-top: 8px;
        font-size: 15px;
        line-height: 160%;
    }
}

@media(min-width: 960px) {
    .news-aside {
        &__item{
            &:hover {
                .news-aside {
                    &__name {
                        color: @green;
                    }
                }
            }
        }
    }
}