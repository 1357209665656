.menu-catalog {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    margin-bottom: 77px;


    &__img {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
    }

    &__name {
        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
        color: #262526;
        transition: color 0.3s ease;
    }

    &__link {
        display: flex;
        justify-content: center;

        a {
            color: @lg3;
            font-weight: bold;
            font-size: 36px;
            line-height: 42px; 
            border-bottom: 4px solid fade(@lg3 , 30);
            transition: border-color 0.3s ease;
        }
    }

    &__item {
        text-align: center;
        width: calc(~"20% - 32px");
        margin: 0 16px;
        display: inline-block;
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .menu-catalog {
        margin-bottom: 6vw;

        &__img {
            width: 7vw;
            height: 7vw;
            margin-left: auto;
            margin-right: auto;

            img {
                max-width: 100%;
            }
        }

        &__name {
            font-size: 1.1vw;
        }

        &__link {
            a {
                line-height: 1.2;
                font-size: 2.3vw;
            }
        }
    }
}

@media(min-width: 1141px) {
    .menu-catalog {
        &__item {
            &:hover {
                .menu-catalog {
                    &__name {
                        color: @green;
                    }
                }
            }
        }

        &__link {
            a {
                &:hover {
                    border-bottom-color: fade(@lg3 , 100);
                }
            }
        }
    }
}