.banner-inner {
    position: relative;
    background-position: center;
    background-size: cover;
    padding-top: 113px;
    padding-bottom: 227px;
    margin-top: -101px;
    margin-bottom: -136px;

    &::before {
        content:" ";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.63) -15.83%, rgba(0, 0, 0, 0) 82.03%, rgba(0, 0, 0, 0) 108.95%);
    }

    &__wrap {
        position: relative;
        z-index: 1;
        max-width: 767px;
        margin: 0 auto;
        text-align: center;
    }

    &__title {
        font-weight: bold;
        font-size: 68px;
        line-height: 80px;
        color: #FFFFFF;
        margin-bottom: 16px;
    }

    &__text {
        font-weight: bold;
        font-size: 20px;
        line-height: 132%;
        color: #F4F3F4;
        margin-bottom: 40px;
    }
}

@media(max-width: 1140px) {
    .banner-inner {
        padding-top: 155px;
        padding-bottom: 177px;
        margin-bottom: -112px;

        &__title {
            font-size: 52px;
            line-height: 61px;
        }

        &__wrap {
            max-width: 516px;
        }

        &__text {
            font-size: 17px;
            line-height: 132%;
            margin-bottom: 29px;
        }

        &__btn {
            .btn {
                font-size: 14px;
                line-height: 16px; 
                padding: 14px 32px 14px;
            }
        }
    }
}



@media(max-width: 960px) {
    .banner-inner {
        padding-top: 106px;
        padding-bottom: 90px;
        margin-bottom: -42px;

        &__title {
            font-size: 46px;
            line-height: 54px;
        }

        &__wrap {
            max-width: 465px;
        }

        &__text {
            font-size: 15px;
            line-height: 132%;
            margin-bottom: 31px;
        }

        &__btn {
            .btn {
                font-size: 12px;
                line-height: 14px;
                padding: 10px 20px 9px;
            }
        }
    }
}

@media(max-width: 640px) {
    .banner-inner {
        padding-top: 81px;
        padding-bottom: 82px;
        margin-bottom: -50px;
        margin-top: -76px;

        &__title {
            font-size: 36px;
            line-height: 42px; 
        }

        &__wrap {
            max-width: 371px;
        }
    }
}

@media(max-width: 480px) {
    .banner-inner {
        padding-top: 72px;
        padding-bottom: 71px;
        margin-bottom: 0;
        margin-top: -77px;

        &__text {
            margin-bottom: 25px;
        }

        &__title {
            font-size: 30px;
            line-height: 35px;
        }
    }
}