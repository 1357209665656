.gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -11px;
    margin-top: 64px;
    margin-bottom: 36px;

    &__title {
        font-style: italic;
        font-weight: 300;
        font-size: 16px;
        line-height: 156%;
        margin-top: -45px;
        margin-bottom: 36px;
    }

    &__item {
        display: block;
        height: 270px;
        width: calc(~"50% - 22px");
        margin: 0 11px 22px;
    }
}

@media(max-width: 640px) {
    .gallery {
        &__item {
            height: 40vw;
        }
    }
}

@media(max-width: 640px) {
    .gallery {
        &__item {
            width: calc(~"100% - 22px");
            height: 60vw;
        }
    }
}