.programm {

    &__type {
        font-size: 18px;
        line-height: 100%;
        color: @lightbrown;
        margin-bottom: 8px;
        opacity: 0.5;
    }

    &__heading {
        font-weight: 500;
        font-size: 18px;
        line-height: 132%;
        color: @dark2;
        margin-bottom: 32px;
    }


    &__info {
        .list-icon {
            &__item {
                margin-bottom: 24px;
                min-height: inherit;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__text {
                font-weight: normal;
            }
        }
    }

    &__body {
        width: 66%;
        padding-right: 57px;
        flex: none;
    }

    &__img {
        display: inline-flex;
        align-items: center;

        svg {
            display: block;
           margin: 0 4px;
        }
    }

    &__aside {
        padding-bottom: 15px;
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;

        &--mobile {
            display: none;
        }
    }

    &__item {
        background-color: #FFFFFF;
        border-radius: 16px;  
        padding: 32px;
        margin-bottom: 32px;
        transition: box-shadow 0.3s ease;

        &--big {
            display: flex;
            align-items: center;
            padding: 40px 48px;
            border-radius: 32px;
            box-shadow: 0px 13px 32px rgba(0, 0, 0, 0.03);

            .programm {
                &__name {
                    font-size: 28px;
                    line-height: 33px;
                }

                &__btn {
                    flex-direction: column;
                    align-items: flex-start;

                    .btn {
                        margin-top: 24px;
                    }
                }
            }
        }

        &--small {
            padding: 0;
            background-color: transparent;

            .programm {
                &__name {
                    font-size: 20px;
                    line-height: 132%;
                    display: inline-flex;
                    margin-bottom: 8px;
                    color: inherit;
                }

                &__img {
                    img {
                        width: 32px;
                    }
                }

                &__btn {
                    margin-top: 8px;
                }

                &__text {
                    font-size: 15px;
                    line-height: 160%;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__text {
        color: @dark4;
    }



    &__name {
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 16px;
        transition: color 0.3s ease;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -24px -48px;

        .programm {
            &__item {
               
                margin: 0 24px 48px;
                width: calc(~"50% - 48px");
            }
        }
    }
}

@media(min-width: 960px) {
    .programm {
        &__item {
            &:hover {
                box-shadow: 0px 13px 32px rgba(0, 0, 0, 0.1), 0px 13px 32px rgba(0, 0, 0, 0.03); 
            }

            &--small {
                &:hover {
                    box-shadow: none;
                    
                    .programm__name {
                        color: @green;
                    }
                }
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 1141px) {
    .programm {
        &__item {
            &--big {
                padding: 2.8vw 3.2vw;

                .programm {
                    &__name {
                        font-size: 1.95vw;
                        margin-bottom: 0.8vw;
                    }

                    &__text {
                        font-size: 1.25vw;
                        line-height: 1.5;
                    }
                }
            }
        }

        &__info {
            .list-icon {
                &__text {
                    font-size: 1.25vw;
                }

                &__item {
                    margin-bottom: 1vw;
                }

                &__icon {
                    width: 3vw;
                    margin-right: 2.5vw;
                }
            }
        }

        &__text {
            font-size: 1.2vw;
        }

        &__body {
            width: 52%;
        }

        &__type {
            font-size: 1.25vw;
            margin-bottom: 0.3vw;
        }

        &__heading {
            font-size: 1.15vw;
            margin-bottom: 1vw;
        }

        &--small {
            .programm {
                &__name {
                    font-size: 1.2vw;
                    margin-bottom: 0.4vw;
                }

                &__text {
                    font-size: 1vw;
                    line-height: 153%;
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .programm {

        &__body {
            width: 52%;
            padding-right: 32px;
        }

        &__heading {
            font-size: 14.25px;
            line-height: 165%;
            margin-bottom: 16px;
        }

        &__info {
            .list-icon {
                &__item {
                    margin-bottom: 15px;
                }

                &__text {
                    font-size: 14.25px;
                    line-height: 165%;
                }
            }
        }

        &__item {
            padding: 26px 24px 24px;

            &--big {
                padding: 32px 32px 24px;

                .programm {
                    &__name {
                        font-size: 20px;
                        line-height: 23px;
                    }
                    
                    &__btn {
                        .btn {
                            font-size: 9.49582px;
                            line-height: 11px; 
                        }
                    }

                    &__type {
                        margin-bottom: 4px;
                    }
                }
            }

            &--small {
                padding: 0;
                margin-bottom: 16px;

                .programm {
                    &__name {
                        font-size: 17px;
                        line-height: 132%;
                    }

                    &__text {
                        font-size: 12px;
                        line-height: 165%;
                    }
                }
            }
        }

        &__name {
            font-size: 17px;
            line-height: 132%;
        }

        &__text {
            font-size: 14.25px;
            line-height: 169%;
        }

        &__type {
            font-size: 14.25px;
            line-height: 165%;
            margin-bottom: 0;
        }

       
    }
}

@media(max-width: 960px) {
    .programm {

        &__item {
            &--big {
                padding: 25px 25px 24px;
                flex-direction: column;

                .programm {
                    &__type {
                        margin-bottom: 2px;
                    }

                    &__btn {
                        margin-top: 20px;

                        .btn {
                            font-size: 12px;
                            line-height: 14px; 
                        }
                    }

                    &__name {
                        margin-bottom: 8px;
                    }

                    &__btn {
                        .btn {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        &__info {
            width: 100%;

            .list-icon {
                &__icon {
                    align-items: center;
                    margin-right: 12px;
                    width: 30px;
                    height: 30px;
                }
            }
        }

        &__body {
            margin-bottom: 24px;

            .btn {
                display: none;
            }
        }

        &__btn {
            &--mobile {
                display: flex;
                width: 100%;
                justify-content: flex-start;

               
            }
        }

        &__body {
            width: 100%;
            padding-right: 0;
        }
    }
}

@media(max-width: 480px) {
    .programm {
        &__info {
            .list-icon {
                &__item {
                    display: flex;
                    text-align: left;
                }

                &__icon {
                    margin-left: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}